<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Nome*"
                          rounded filled
                          v-model="editingItem.name.it"
                          :error="nameError"
                          :error-messages="requiredErrorMessages(nameError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Codice*"
                          rounded filled
                          v-model="editingItem.abbrev.it"
                          :error="abbrevError"
                          :error-messages="requiredErrorMessages(abbrevError)"
                  ></v-text-field>
                </v-col>


              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  export default {
    name: "GenreForm",
    data () {
      return {
        editingItem : {
          name : { it : ''},
          abbrev : { it : ''},
        },
        nameError : false,
        abbrevError : false,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root']),
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuovo genere' : 'Modifica genere';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert']),
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'genre', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati del genere';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.nameError = false;
        this.abbrevError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;


        if(!this.editingItem.name.it) {
          this.nameError = true;
          return;
        }

        if(!this.editingItem.abbrev.it) {
          this.abbrevError = true;
          return;
        }

        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'genre', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Genere salvato"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/genre");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/genre/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  name : { it : ''},
                  abbrev : { it : ''},
                };
              } else {
                this.$router.replace("/genre/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save genre error:", err);
          let message = err.message ? err.message : "errore al salvataggio del genere";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
