<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Calendario</h2>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <h4>Giorni di apertura della settimana</h4>
                </v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Lunedì" hide-details v-model="editingItem.open_days.lun" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Martedì" hide-details v-model="editingItem.open_days.mar" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Mercoledì" hide-details v-model="editingItem.open_days.mer" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Giovedì" hide-details v-model="editingItem.open_days.gio" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Venerdì" hide-details v-model="editingItem.open_days.ven" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Sabato" hide-details v-model="editingItem.open_days.sab" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Domenica" hide-details v-model="editingItem.open_days.dom" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-btn small color="accent" rounded @click="saveSettings()">Salva</v-btn></v-col>

                <v-col cols="12"><v-divider /></v-col>
                <v-col cols="12">
                  <h4>Feste nazionali osservate dalla palestra (chiusura)</h4>
                </v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Natale" hide-details v-model="editingItem.holidays.natale" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Santo Stefano" hide-details v-model="editingItem.holidays.santo_stefano" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Capodanno" hide-details v-model="editingItem.holidays.capodanno" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Pasquetta" hide-details v-model="editingItem.holidays.pasquetta" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Festa della liberzione" hide-details v-model="editingItem.holidays.liberazione" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Festa del lavoro" hide-details v-model="editingItem.holidays.lavoro" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Festa della repubblica" hide-details v-model="editingItem.holidays.repubblica" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Ferragosto" hide-details v-model="editingItem.holidays.ferragosto" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Tutti i santi" hide-details v-model="editingItem.holidays.santi" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-checkbox label="Festa dell'Immacolata" hide-details v-model="editingItem.holidays.immacolata" /></v-col>
                <v-col cols="6" md="4" lg="3" xl="2" class="d-flex align-end"><v-btn small color="accent" rounded @click="saveSettings()">Salva</v-btn></v-col>
                <v-col cols="12"><v-divider /></v-col>
                <v-col cols="12">
                  <h4>Periodi di ferie programmate</h4>
                </v-col>
                <v-col cols="12">
                  <v-simple-table dark dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">Dal</th>
                        <th class="text-left">Al</th>
                        <th class="text-left" width="80">Elimina</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in holidays" :key="item._id">
                        <td>{{ formatDateTime(item.from) }}</td>
                        <td>{{ formatDateTime(item.to) }}</td>
                        <td><v-btn text icon color="error" @click="removeHoliday(item)"><v-icon>mdi-close</v-icon></v-btn></td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12"><h4>Aggiungi periodo di ferie</h4></v-col>
                <v-col cols="12" lg="4">
                  <v-text-field type="date" outlined rounded label="Dal" v-model="newHoliday.from" />
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field type="date" outlined rounded label="Al" v-model="newHoliday.to" />
                </v-col>
                <v-col cols="12" lg="4">
                  <v-btn color="accent" rounded @click="addHoliday()">Inserisci</v-btn>
                </v-col>

                <v-col cols="12"><h4>Aggiungi giorno/ora di ferie</h4></v-col>
                <v-col cols="12" sm="6" md="4" xl="3">
                  <v-date-picker v-model="newHolidayDate" full-width class="elevation-1"></v-date-picker>
                </v-col>
                <v-col cols="12" sm="6" md="4" xl="3">
                  <v-time-picker format="24hr" full-width v-model="newHolidayTime" class="elevation-1" :allowed-minutes="allowedMinutes" ></v-time-picker>
                </v-col>
                <v-col cols="12" sm="6" md="4" xl="3">
                  <v-btn color="accent" rounded @click="addHolidayDay()" class="ma-3">Inserisci giorno</v-btn>
                  <v-btn color="accent" rounded @click="addHolidayHour()" class="ma-3">Inserisci ora</v-btn>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import utils from '../utils';
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import {DateTime} from "luxon";

export default {
  name: "Calendar",
  data () {
    return {
      mailTo : '',
      editingItem : {
        home_kit_price : 0,
        home_kit_expedition_price : 0,
        home_kit_expedition_days : 0,
        purchase_expiration_days_after_first_use : 60,
        open_days : {
          lun: true,
          mar: true,
          mer: true,
          gio: true,
          ven: true,
          sab: true,
          dom: false,
        },
        free_seat_every_x_seats : 30,
        restricted_users_starts_from_num : 200,
        user_bookings_per_week : 2,
        user_bookings_per_day : 1,
        seats_days_of_validity : 365,
        bookings_per_hour : 9,
        one_to_one_per_hour : 1,
        cancel_within_minutes : 1,
        booking_within_minutes : 10,
        holidays : {
          natale : true,
          santo_stefano : true,
          capodanno : true,
          epifania : true,
          pasquetta : true,
          liberazione : true,
          lavoro : true,
          repubblica : true,
          ferragosto : true,
          santi : true,
          immacolata : true,
        }
      },
      holidays : [],
      newHoliday : {
        from : null,
        to: null
      },
      newHolidayDate : DateTime.now().toISODate(),
      newHolidayTime :  DateTime.now().toFormat('HH') + ":00",
      hours : [],
      newHour : {
        day : 1,
        time: "09:00",
        is_one_to_one : false,
        restricted : false,
      },
      days : [
        { text : 'Lunedì', value : 1},
        { text : 'Martedì', value : 2},
        { text : 'Mercoledì', value : 3},
        { text : 'Giovedì', value : 4},
        { text : 'Venerdì', value : 5},
        { text : 'Sabato', value : 6},
        { text : 'Domenica', value : 0},
      ]
    }

  },
  computed : {
    ...mapGetters(['token', 'profile', 'is_root', 'users']),
    loading : {
      get() { return this.$store.state.loading; },
      set(val) { this.$store.state.loading = val; }
    },
    lunHours() {
      return this.hours.filter((item) => item.day === 1);
    },
    marHours() {
      return this.hours.filter((item)=> item.day === 2);
    },
    merHours() {
      return this.hours.filter((item)=> item.day === 3);
    },
    gioHours() {
      return this.hours.filter((item)=> item.day === 4);
    },
    venHours() {
      return this.hours.filter((item)=> item.day === 5);
    },
    sabHours() {
      return this.hours.filter((item)=> item.day === 6);
    },
    domHours() {
      return this.hours.filter((item)=> item.day === 0);
    },
    maxHours() {
      return Math.max(this.lunHours.length, this.marHours.length, this.merHours.length, this.gioHours.length, this.venHours.length, this.sabHours.length, this.domHours.length)
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update','upload', 'insert','refreshCollection']),
    allowedMinutes: v => v === 0,
    hourStyleClass(hour){
      let classes = ["hour-cell"]
      if(hour.is_one_to_one) {
        classes.push('one-to-one')
      }
      if(hour.is_one_to_one) {
        classes.push('restricted')
      }
      return classes.join(' ')
    },
    requiredErrorMessages(err) {
      if(err) return ["Campo richiesto"];
      return []
    },
    formatDate(date) {
      return utils.formatDate(date)
    },
    formatDateTime(date) {
      return utils.formatDateTime(date)
    },
    refreshHolidays() {
      this.loading = true;
      this.load({collection:'holidays', filter : utils.noFilter()}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.holidays = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento delle impostazioni';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    addHoliday() {
      if(!this.newHoliday.from || !this.newHoliday.from) {
        this.sendError({message:"Inserisci delle date valide"});
        return;
      }
      let from = utils.toDateTime(this.newHoliday.from);
      let to = utils.toDateTime(this.newHoliday.to);

      if(!from.isValid || !to.isValid) {
        this.sendError({message:"Inserisci delle date valide"});
        return;
      }
      to = to.endOf("day")
      this.requestConfirm({title:"Richiesta conferma", message:"Vuoi davvero inserire questo periodo di chiusura della palestra?", callback: (confirmed) => {
          if(confirmed) {
            this.loading = true;
            let data = { from : from.toISO(), to : to.toISO() }
            this.insert({collection:'holidays', data}).then((reply)=>{
              this.loading = false;
              if(reply.data) {
                this.holidays.push(reply.data);
              }
            }).catch((err) => {
              this.loading = false;
              let message = err.message ? err.message : 'errore al salvataggio delle impostazioni';
              this.sendError({message});
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });

    },
    addHolidayDay() {
      if(!this.newHolidayDate){
        this.sendError({message:"Inserisci una data valida"});
        return;
      }
      let from = utils.toDateTime(this.newHolidayDate).startOf("day");
      let to = utils.toDateTime(this.newHolidayDate).endOf("day");


      this.requestConfirm({title:"Richiesta conferma", message:`Vuoi davvero inserire questo giorno di chiusura della palestra: ${from.toFormat('dd/MM/yyyy')}?`, callback: (confirmed) => {
          if(confirmed) {
            this.loading = true;
            let data = { from : from.toISO(), to : to.toISO() }
            this.insert({collection:'holidays', data}).then((reply)=>{
              this.loading = false;
              if(reply.data) {
                this.holidays.push(reply.data);
              }
            }).catch((err) => {
              this.loading = false;
              let message = err.message ? err.message : 'errore al salvataggio delle impostazioni';
              this.sendError({message});
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });
    },
    addHolidayHour() {
      //console.log(this.newHolidayDate, this.newHolidayTime)
      if(!this.newHolidayDate || !this.newHolidayTime){
        this.sendError({message:"Inserisci una data e un ora valida"});
        return;
      }

      let from = DateTime.fromFormat(`${this.newHolidayDate} ${this.newHolidayTime}`, 'yyyy-MM-dd HH:mm').startOf("hour");
      let to = DateTime.fromFormat(`${this.newHolidayDate} ${this.newHolidayTime}`, 'yyyy-MM-dd HH:mm').endOf("hour");

      this.requestConfirm({title:"Richiesta conferma", message:`Vuoi davvero inserire questa ora di chiusura della palestra: ${from.toFormat('dd/MM/yyyy HH:mm')}?`, callback: (confirmed) => {
          if(confirmed) {
            this.loading = true;
            let data = { from : from.toISO(), to : to.toISO() }
            this.insert({collection:'holidays', data}).then((reply)=>{
              this.loading = false;
              if(reply.data) {
                this.holidays.push(reply.data);
              }
            }).catch((err) => {
              this.loading = false;
              let message = err.message ? err.message : 'errore al salvataggio delle impostazioni';
              this.sendError({message});
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });
    },
    removeHoliday( item ) {

      this.requestConfirm({title:"Richiesta conferma", message:"Vuoi davvero eliminare questo periodo di chiusura della palestra?", callback: (confirmed) => {
          if(confirmed) {
            this.loading = true;
            this.delete({collection:'holidays', id: item._id}).then((reply)=>{
              this.loading = false;
              this.refreshHolidays()
            }).catch((err) => {
              this.loading = false;
              let message = err.message ? err.message : 'errore al caricamento delle impostazioni';
              this.sendError({message});
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });

    },
    refreshHours() {
      this.loading = true;
      this.load({collection:'hours', filter : utils.fullFilter({}, 0,1, 'time', 'ASC')}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.hours = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento degli orari';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    loadData() {
      this.loading = true;
      this.get({collection : 'settings'}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento delle impostazioni del calendario';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },

    saveSettings() {
      this.loading = true;
      this.update({collection:'settings', data : this.editingItem, noId: true}).then((reply)=>{
        this.loading = false;
        if(!reply.data) {
          if(reply.message) {
            this.sendError({message: reply.message});
          } else {
            this.sendError({message: 'Oops! c\'è stato un errore'});
          }
          return
        }

        this.editingItem = reply.data;
      }).catch((err)=>{
        if(config.debug) console.log("Save settings error:", err);
        let message = err.message ? err.message : "errore al salvataggio delle impostazioni del calendario";
        this.sendError({ message });
        if(err.statusCode === 401) {
          this.$router.replace("/");
          this.logout();
        }
      })
    }
  },
  mounted() {
    this.loadData()
    this.refreshHolidays()
    this.refreshHours()
  }
}
</script>

<style scoped></style>
