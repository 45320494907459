<template>
  <v-app>
    <v-navigation-drawer
        v-if="loggedIn"
        v-model="drawer"
        :width="220"
        clipped
        fixed
        dark
        app>

      <v-list dense>
        <div v-for="page in pages" :key="page.link">
          <v-list-group v-if="page.items" :prepend-icon="page.icon" :value="isSubPageActive(page.items)" active-class="accent--text">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{page.title}}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="subpage in page.items" :key="subpage.link" :to="subpage.link" router class="menu-subitem pl-6" active-class="accent--text">
              <v-list-item-action class="mr-2">
                <v-icon>{{subpage.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ subpage.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :to="page.link" router active-class="accent--text">
            <v-list-item-action class="mr-2">
              <v-icon>{{page.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>


        <v-list-item @click="logout()">
          <v-list-item-action class="mr-2">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Exit</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!--
      <v-list dense>

        <template v-for="page in pages" >
          <v-list-item v-if="page.type === 'item'" :to="page.link" router active-class="accent--text" :key="page.link">
            <v-list-item-action>
              <v-icon>{{page.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{page.title}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-subheader v-if="page.type === 'header'" :key="page.link">{{page.title}}</v-subheader>

          <v-list-item-group v-if="page.type === 'group'" v-model="page.group" :key="page.link">
            <v-list-item v-for="subpage in page.items" :key="subpage.link" :to="subpage.link" router active-class="accent--text">
              <v-list-item-action>
                <v-icon>{{subpage.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{subpage.title}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </template>


      </v-list> -->
    </v-navigation-drawer>
    <v-app-bar app
               fixed clipped-left
               color="secondary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img alt="Top Up Gym Logo" class="shrink mr-2 d-none d-sm-flex" @click="goHome()" width="198"
               contain src="./assets/logo-esteso-alpha.png" transition="scale-transition" height="64"
        />
        <v-img alt="Top Up Gym Logo" class="shrink mr-2 d-sm-none" @click="goHome()" width="48"
               contain src="./assets/logo-alpha.png" transition="scale-transition" height="48"
        />
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y v-if="loggedIn">
          <template v-slot:activator="{ on, attrs }">
            <v-badge :content="numNotifications" :value="numNotifications" color="error" overlap class="align-self-center">
              <v-btn icon color="accent" v-on="on" v-bind="attrs"><v-icon>mdi-bell</v-icon></v-btn>
            </v-badge>
          </template>
          <v-list>
            <v-list-item v-for="notif in notifications" :key="notif._id" @click="openNotification(notif)" two-line >
              <v-list-item-content>
                <v-list-item-title :class="notificationClass(notif)">{{ notif.text }}</v-list-item-title>
                <v-list-item-subtitle class="body-1 grey--text fw-1">{{ formatDate(notif.time) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!notifications.length">
              <v-list-item-title class="body-1 grey--text font-italic no-select">Non ci sono notifiche al momento.</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>


        <v-btn v-if="loggedIn" color="accent" @click="logout()" text>Logout</v-btn>
        <v-dialog v-else v-model="loginDialog" persistent max-width="320">
          <template v-slot:activator="{ on }">
            <v-btn color="accent" text v-on="on">Login</v-btn>
          </template>
          <v-form @submit="onSubmit">
            <v-card class="pt-3">
              <v-img class="ml-3 mr-3" src="./assets/logo-h.png" transition="scale-transition" />
              <v-card-title class="headline fw-1 text-uppercase">Login</v-card-title>
              <v-card-text>
                <v-text-field rounded filled v-model="loginEmail" label="Email"></v-text-field>
                <v-text-field rounded filled v-model="loginPassword" label="Password" type="password" ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="accent" type="submit" text >Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>

        </v-dialog>
      </v-toolbar-items>
    </v-app-bar>

    <v-main class="grey lighten-1">
      <v-fade-transition>
        <router-view  v-if="loggedIn"/>
      </v-fade-transition>

      <v-dialog v-model="confirmDialog" persistent max-width="320">
        <v-card rounded>
          <v-card-title class="headline">{{confirmDialogTitle}}</v-card-title>
          <v-card-text>
            <p class="body-1" v-html="confirmDialogMessage"></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="onConfirmDialogExit(false)">No</v-btn>
            <v-btn color="error" text @click="onConfirmDialogExit(true)">Sì</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="loading" persistent max-width="200">
        <v-card>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="align-content-center align-center justify-center">
                  <v-progress-circular style="left: 50%; margin-left: -16px" indeterminate color="primary"></v-progress-circular>
                  <h3 style="width: 100%; text-align: center">{{loadingMessage}}</h3>
                </v-col>
              </v-row>
            </v-container>

          </v-card-text>
        </v-card>
      </v-dialog>

      <v-snackbar
          dark
          :timeout="5000"
          :color="snackbarColor"
          top
          multi-line
          v-model="snackbar">
        {{dialogMessage}}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import config from './config'
import utils from "./utils";
export default {
  name: 'App',
  data () {
    return {
      drawer : true,
      loginEmail : null,
      loginPassword : null,
      tokenInterval : -1,
      notifInterval : -1
    }
  },
  computed : {
    ...mapState(['confirmDialogTitle','confirmDialogCallback', 'dialogMessage', 'loadingMessage']),
    ...mapGetters(['token','profile','is_admin','is_root','is_editor','is_viewer','confirmDialogMessage','notifications']),
    pages() {
      let items = [{ title : "Dashboard", icon : "mdi-view-dashboard", link : "/" }];
      if(this.is_editor) {
        items.push(
            { title : "Gestione Dati", icon: 'mdi-package-variant', items : [
                { type : 'item', title : "Pacchetti", icon : "mdi-dumbbell", link : "/kits" },
                { type : 'item', title : "Esercizi", icon : "mdi-run-fast", link : "/exercises" },
                { type : 'item', title : "Categorie", icon : "mdi-tag", link : "/categories" },
                { type : 'item', title : "Sottocategorie prodotto", icon : "mdi-tag", link : "/subcategories" },
                { type : 'item', title : "Difficoltà", icon : "mdi-alert", link : "/difficulties" },
                { type : 'item', title : "Generi", icon : "mdi-gender-male-female", link : "/genre" },
                { type : 'item', title : "Media", icon : "mdi-play-network", link : "/media" },
                { type : 'item', title : "Banner", icon : "mdi-advertisements", link : "/banners" },
                { type : 'item', title : "Gallery", icon : "mdi-image-multiple", link : "/gallery" },
              ]
            },
            { title : "Gestione App", icon:'mdi-cellphone-cog', items : [
                { type : 'item', title : "Sezioni Home", icon : "mdi-tablet-dashboard", link : "/home" },
                { type : 'item', title : "Pagine Statiche", icon : "mdi-text", link : "/cms" },
              ]
            },
            { title : "Prenotazioni", icon: 'mdi-calendar-month', items : [
                { type : 'item', title : "TopGym Experience", icon : "mdi-calendar-account", link : "/topgymexperience" },
                { type : 'item', title : "Sala Corsi", icon : "mdi-calendar-account", link : "/salacorsi" },
                { type : 'item', title : "Open Gym", icon : "mdi-calendar-account", link : "/opengym" },
                { type : 'item', title : "Bike indoor", icon : "mdi-calendar-account", link : "/gym" },
                { type : 'item', title : "Vacu", icon : "mdi-calendar-account", link : "/vacu" },
                { type : 'item', title : "Visite mediche", icon : "mdi-calendar-account", link : "/medical" },
                { type : 'item', title : "Aperture e Ferie", icon : "mdi-glass-cocktail", link : "/calendar" },
                { type : 'item', title : "Preferenze prenotazioni", icon : "mdi-order-bool-ascending-variant", link : "/bookingprefs" },
              ]
            },
        )
      } else {
        items.push(
            { title : "Prenotazioni", icon: 'mdi-calendar-month', items : [
                { type : 'item', title : "TopGym Experience", icon : "mdi-calendar-account", link : "/topgymexperience" },
                { type : 'item', title : "Sala Corsi", icon : "mdi-calendar-account", link : "/salacorsi" },
                { type : 'item', title : "Open Gym", icon : "mdi-calendar-account", link : "/opengym" },
                { type : 'item', title : "Bike indoor", icon : "mdi-calendar-account", link : "/gym" },
                { type : 'item', title : "Vacu", icon : "mdi-calendar-account", link : "/vacu" },
                { type : 'item', title : "Visite mediche", icon : "mdi-calendar-account", link : "/medical" },
              ]
            },
        );
      }

      if(this.is_editor) {

      }


      if(this.is_admin) {
        items.push(
            { type : 'item', title : "Vendite", icon : "mdi-cart", link : "/sells" },
            { type : 'item', title : "Report utenti", icon : "mdi-format-list-checks", link : "/reports" },
            { title: 'Gestione utenti', icon:'mdi-account-box-multiple', items: [
                { title : "Visite Mediche", icon : "mdi-doctor", link : "/mediche" },
                { title : "Tesserati MSP", icon : "mdi-card-account-details", link : "/cards" },
                { title : "Utenti", icon : "mdi-account", link : "/users" },
              ]
            },
            { title : "Impostazioni", icon : "mdi-cog", link : "/settings" },
        );
      }
      return items
    },
    loggedIn() {
      return this.profile != null
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
    loginDialog : {
      get() { return this.$store.state.loginDialog },
      set(newVal) {  this.$store.state.loginDialog = newVal }
    },
    successDialog : {
      get() { return this.$store.state.successDialog },
      set(newVal) {  this.$store.state.successDialog = newVal }
    },
    warningDialog : {
      get() { return this.$store.state.warningDialog },
      set(newVal) {  this.$store.state.warningDialog = newVal }
    },
    errorDialog : {
      get() { return this.$store.state.errorDialog },
      set(newVal) {  this.$store.state.errorDialog = newVal }
    },
    infoDialog : {
      get() { return this.$store.state.infoDialog },
      set(newVal) {  this.$store.state.infoDialog = newVal }
    },
    confirmDialog : {
      get() { return this.$store.state.confirmDialog },
      set(newVal) {  this.$store.state.confirmDialog = newVal }
    },
    snackbar : {
      get() {
        return this.successDialog || this.warningDialog || this.errorDialog || this.infoDialog;
      },
      set(newVal) {
        this.successDialog = newVal;
        this.warningDialog = newVal;
        this.errorDialog = newVal;
        this.infoDialog = newVal;
      }
    },
    snackbarColor() {
      if(this.successDialog) return 'success';
      if(this.errorDialog) return 'error';
      if(this.warningDialog) return 'warning';
      return undefined;
    },
    numNotifications() {
      let num = this.notifications.filter((item) => {
        return !item.read_by || !item.read_by.includes(this.profile._id);
      }).length;
      return num;
    }
  },
  methods: {
    ...mapActions(['login', 'logout', 'loadProfile', 'refreshToken', 'setToken', 'setProfile','sendError', 'loadNotifications','setNotificationRead']),
    isSubPageActive(items){
      let filter = items.filter((item) => {
        //console.log(this.$route.path, item.link);
        return this.$route.path.includes(item.link, 0);
      })
      return filter.length > 0
    },
    notificationClass(notification) {
      if(notification.read_by && notification.read_by.includes(this.profile._id)) {
        return 'grey--text body-1 no-select';
      }
      return 'accent--text body-1 font-weight-bold no-select';
    },
    openNotification(notification) {
      this.setNotificationRead({ id : notification._id });
      this.$router.push(notif.link);
    },
    formatDate : utils.formatDateTime,
    onSubmit(e) {
      e.preventDefault();
      this.onLoginClick();
    },
    onLoginClick() {
      this.loginDialog = false;
      this.loading = true;
      this.login({ email : this.loginEmail, password : this.loginPassword}).then((reply) => {
        if(config.debug) console.log(reply);
        this.setToken({ token : reply.token });
        this.setProfile({ profile : reply.user });
        clearInterval(this.tokenInterval);
        clearInterval(this.notifInterval);
        this.tokenInterval = setInterval(this.refreshToken, 480000);
        this.notifInterval = setInterval(this.loadNotifications, 60000);
        this.$forceUpdate();
        this.loading = false;
      }).catch((err) => {
        this.sendError( { message : (err.message ? err.message : "errore sconosciuto") } );
        this.loading = false;
        this.logout()
      });
    },

    onConfirmDialogExit( positive ) {
      this.confirmDialogCallback(positive);
      this.confirmDialog = false
    },
    getProfile(){
      this.loadProfile().then(()=>{
        this.loadNotifications();
        clearInterval(this.tokenInterval);
        clearInterval(this.notifInterval);
        this.tokenInterval = setInterval(this.refreshToken, 480000);
        this.notifInterval = setInterval(this.loadNotifications, 60000);
        this.$forceUpdate();
        this.loading = false;
        //console.log(this.profile != null);
      }).catch(this.logout);
    }
  },
  mounted(){
    if( this.token ) {
      this.loading = true;
      this.getProfile();
    } else {
      this.logout()
    }


  }
};
</script>
