<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2 class="display-1 ml-4">Lista dei report utente</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-autocomplete rounded solo v-model="userSearch" label="Utente" :items="userOptions" @input="applyFilter"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo type="date" v-model="fromSearch" label="Ricevuti dal" clearable @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo type="date" v-model="toSearch" label="Ricevuti fino al" clearable @input="applyFilter"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                              no-data-text="Non abbiamo ricevuto report al momento."  :footer-props="footerProps">

                                    <template v-slot:item.user.surname="{ item }">
                                        <a :href="'./#/reports/'+item._id" ><b>{{ item.user.surname }}</b> {{ item.user.name }}</a>
                                    </template>
                                    <template v-slot:item.edit="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'report view', params : { id : item._id }})">
                                            <v-icon dark>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.remove="{ item }">
                                        <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import config from "../config";
    export default {
        name: "ReportList",
        data () {
            return {
                footerProps : {
                    "items-per-page-text" : "N° risultati per pagina",
                    "items-per-page-options" : [10,20,50,100],
                    "items-per-page" : 10
                },
                userSearch : null,
                fromSearch : null,
                toSearch : null,

                tableLoading : false,
                count : 0,
                items : [],

                pagination: {},
                query : {}
            }
        },
        watch: {
            pagination: {
                handler () {
                    this.refresh();
                },
                deep: true
            }
        },
        computed : {
            ...mapGetters(['profile','is_admin','is_root','users']),
            headers() {
                let cols = [
                    { text : 'Cognome e nome', sortable: true, align: 'left', value : 'user.surname' },
                    { text : 'Pacchetto', sortable: true, align: 'left', value : 'kit.title.it' },
                    { text : 'Prodotto', sortable: true, align: 'left', value : 'product.title.it' },
                    { text : 'Lezione', sortable: true, align: 'left', value : 'lesson.title.it' },
                    { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
                ]
                if(this.is_admin || this.is_root) {
                    cols.push({ text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 })
                }
                return cols;
            },
          userOptions() {
            return this.users.map((item)=>{
              return {text:item.surname + ' ' + item.name, fiscal_code : item.fiscal_code || "", value : item._id};
            })
          },
        },
        methods : {
            ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','upload']),
            applyFilter() {
                this.query = {};
                this.query.user = this.userSearch ? this.userSearch : undefined;
                this.query.from = this.fromSearch ? this.fromSearch : undefined;
                this.query.to = this.toSearch ? this.toSearch : undefined;
                this.refresh();
            },
            requestDelete( id ) {
                this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questo report? I report non dovrebbero essere cancellati, fallo soltanto se sei sicuro di quello che stai facendo', callback: ( accepted )=>{
                        if(accepted) {
                            this.delete({ collection : 'reports', id }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Reporr eliminato!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : 'errore alla cancellazione della tessera';
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di cancellazione della tessera",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            refresh() {
                this.tableLoading = true;
                const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
                let sort = null;
                let order = null;
                if(sortBy.length) {
                    sort = sortBy[0];
                    order = 'ASC';
                    if(sortDesc[0]) order = 'DESC';
                }

                let filter = {
                    query : this.query,
                    limit : itemsPerPage,
                    page, sort, order
                };

                this.load({ collection : 'reports', filter}).then((reply)=>{
                    this.tableLoading = false;
                    if(reply.data) {
                        this.count = reply.count;
                        this.items = reply.data;
                    }
                }).catch((err)=>{
                    this.tableLoading = false;
                    this.sendError({message:"Errore al caricamento dei report."});
                    if(config.debug) console.error("Errore al caricamento dei report: " + err);
                    if(err.statusCode === 401) {
                        this.logout();
                    }
                })
            }
        },
        mounted() {
            this.refresh();
        }
    }
</script>

<style scoped>

</style>