<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Percorso*"
                      rounded filled
                      v-model="editingItem.path"
                      :error="pathError"
                      :error-messages="requiredErrorMessages(pathError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Titolo*"
                          rounded filled
                          v-model="editingItem.title.it"
                          :error="titleError"
                          :error-messages="requiredErrorMessages(titleError)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                      label="Video"
                      rounded filled
                      v-model="editingItem.video_id"
                      :items="videoOptions"
                      prepend-icon="mdi-cloud-upload"
                      clearable
                      @click:prepend="openUploadDialog('video')"
                  ></v-autocomplete>
                  <video class="full-width" v-if="editingItem.video_id" :src="selectedVideoUrl" controls style="background: #eee; object-fit: contain; max-height: 120px"/>
                </v-col>


                <v-col cols="12">
                  <v-textarea rounded filled v-model="editingItem.content.it" label="Descrizione / contenuto testuale"
                              :error="contentError" :error-messages="requiredErrorMessages(contentError)"></v-textarea>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch label="Pubblicata" v-model="editingItem.pub"></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="uploadDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Carica video</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                    label="Nome*"
                    rounded filled
                    v-model="uploadMedia.name"
                    :error="mediaNameError"
                    :error-messages="requiredErrorMessages(mediaNameError)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input v-model="file" chips rounded filled show-size
                              accept=".mp4, .m4v" label="File"
                              placeholder="Clicca qui per scegliere il file"></v-file-input>
              </v-col>
              <v-col cols="12" >
                <v-text-field
                    label="Url"
                    rounded filled
                    persistent-hint
                    hint="In alternativa al file upload"
                    v-model="uploadMedia.url"
                    :disabled="file != null"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="uploading" @click="uploadDialog = false">Annulla</v-btn>
          <v-btn color="error" :disabled="uploading" :loading="uploading" text @click="beginUpload()">Inserisci</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  export default {
    name: "CmsForm",
    data () {
      return {
        editingItem : {
          title : { it : ''},
          content : { it : ''},
          path : '',
          video_id : null,
          pub : false,
        },
        titleError : false,
        contentError : false,
        pathError : false,
        videoError : false,
        mediaNameError : false,
        uploadDialog : false,
        uploading : false,
        uploadMedia: {
          name : '',
          media : 'video',
          url : '',
        },
        file : null,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root','media']),
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuova pagina' : 'Modifica pagina';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      },
      videoOptions() {
        return this.media.filter((item) => {
          return item.media === 'video'
        }).map((item)=>{
          return { text : item.name, value: item._id }
        })
      },
      selectedVideoUrl() {
        let media = this.getMedia(this.editingItem.video_id);
        if(!media) return "";
        return media.url || "";
      },
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection']),
      getMedia( id ) {
        for(let i = 0; i < this.media.length; i++) {
          if(this.media[i]._id === id) {
            return this.media[i];
          }
        }
        return null
      },
      openUploadDialog( mediatype ) {
        this.uploadMedia.media = mediatype;
        this.uploadDialog = true;
        this.uploading = false;
      },
      beginUpload() {
        this.mediaNameError = false;
        if(!this.uploadMedia.name) {
          this.mediaNameError = true;
          return
        }
        this.uploading = true;
        this.insert({collection:"media", data: this.uploadMedia}).then((reply)=>{
          if(reply.data) {
            this.editingItem.video_id = reply.data._id;
            if(this.file) {
              let formData = new FormData();
              formData.append("media", this.file);
              this.upload({ where : 'media/' + reply.data._id + '/upload', formData }).then(() => {
                this.refreshCollection({collection: 'media'});
                this.uploading = false;
                this.uploadDialog = false;
                this.file = null;
                this.uploadMedia.name = '';
                this.uploadMedia.url = '';
              }).catch(onError);
              return
            }
            this.refreshCollection({collection: 'media'});
            this.sendSuccess({message: "Media inserito."})
          } else {
            this.sendError({message : 'errore all\'inserimento del media'});
          }
          this.uploading = false;
          this.uploadDialog = false;
        }).catch(onError);

        let onError = (err) => {
          this.uploading = false;
          let message = err.message ? err.message : 'errore all\'inserimento del media';
          this.sendError({message});
          if(err.statusCode === 401) {
            this.logout();
          }
        }
      },
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'cms', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati della pagina';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.titleError = false;
        this.contentError = false;
        this.pathError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;


        if(!this.editingItem.path) {
          this.pathError = true;
          return;
        }
        if(!this.editingItem.title.it) {
          this.titleError = true;
          return;
        }
        if(!this.editingItem.content.it) {
          this.contentError = true;
          return;
        }

        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'cms', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Pagina salvata"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/cms");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/cms/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  title : { it : ''},
                  content : { it : ''},
                  path : '',
                  video_id : null,
                  pub : false,
                };
              } else {
                this.$router.replace("/cms/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save cms error:", err);
          let message = err.message ? err.message : "errore al salvataggio della pagina";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      this.refreshCollection({collection:"media"});
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
