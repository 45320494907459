<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Nome*"
                          rounded filled
                          v-model="editingItem.title"
                          :error="titleError"
                          :error-messages="requiredErrorMessages(titleError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-img v-if="editingItem.media_id" :src="mediaUrl" :aspect-ratio="320/50" class="mb-3"></v-img>
                  <v-autocomplete
                          label="Immagine*"
                          rounded filled
                          v-model="editingItem.media_id"
                          :items="mediaOptions"
                          :error="mediaError"
                          :error-messages="requiredErrorMessages(mediaError)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Link"
                      rounded filled
                      v-model="editingItem.link"
                      hint="deve iniziare per https://"
                      persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Scadenza"
                      rounded filled
                      type="date"
                      v-model="editingItem.exp"
                      hint="dopo la scadenza non verrà più visualizzato"
                      persistent-hint
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Priorità"
                      rounded filled
                      type="number"
                      v-model="editingItem.priority"
                      hint="un numero più alto fa uscire prima il banner"
                      persistent-hint
                      step="1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Pubblicato"
                          v-model="editingItem.pub"
                  ></v-switch>
                </v-col>


              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  export default {
    name: "BannerForm",
    data () {
      return {
        editingItem : {
          title : '',
          media_id : null,
          link : '',
          exp : null,
          priority : 0,
          pub : false,
        },
        titleError : false,
        mediaError : false,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root','media']),
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuovo banner' : 'Modifica banner';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      },
      mediaUrl() {
        if(this.editingItem.media_id) {
          let media = utils.getObjectInArray(this.media, '_id', this.editingItem.media_id);
          if(media) return media.url;
        }
        return null;
      },
      mediaOptions() {
        return this.media.filter(m => m.media === 'banner').map(m => {
          return { text : m.name, value : m._id}
        })
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection']),
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'banners', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati del banner';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.titleError = false;
        this.mediaError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;

        if(!this.editingItem.title) {
          this.titleError = true;
          return;
        }

        if(!this.editingItem.media_id) {
          this.mediaError = true;
          return;
        }

        this.editingItem.priority = utils.toInt(this.editingItem.priority, 0)

        if(this.editingItem.exp) {
          let exp = utils.toDateTime(this.editingItem.exp)
          this.editingItem.exp = exp.isValid ? exp.toISO() : null
        } else {
          this.editingItem.exp = null
        }


        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'banners', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Banner salvato"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/banners");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/banners/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  title : '',
                  media_id : null,
                  link : '',
                  exp : null,
                  priority : 0,
                  pub : false,
                };
              } else {
                this.$router.replace("/banners/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save banner error:", err);
          let message = err.message ? err.message : "Errore al salvataggio del banner";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      this.refreshCollection({collection:'media'})
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
