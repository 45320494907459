<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Informazioni tessera CONI</h2>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <ul>
                    <li class="body-1"><span class="font-weight-bold">Utente:</span> <a v-if="card.user" :href="'/#/users/'+card.user._id">{{card.user.name}} {{card.user.surname}}</a><span v-else class="font-italic">non registrato</span></li>
                    <li class="body-1"><span class="font-weight-bold">Numero tessera:</span> {{card.number}}</li>
                    <li class="body-1"><span class="font-weight-bold">Nome:</span> {{card.name}}</li>
                    <li class="body-1"><span class="font-weight-bold">Cognome:</span> {{card.surname}}</li>
                    <li class="body-1"><span class="font-weight-bold">Codice fiscale:</span> {{card.fiscal_code}}</li>
                    <li class="body-1"><span class="font-weight-bold">Assicurazione:</span> {{card.insurance}}</li>
                    <li :class="cardExpirationClass(card.expiration)"><span class="font-weight-bold">Scadenza:</span> {{formatDate(card.expiration)}}</li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import utils from "../utils";
import {DateTime} from "luxon";
export default {
  name: "CardView",
  data() {
    return {
      card : {
        user : null,
        number : "",
        name : "",
        surname : "",
        fiscal_code : "",
        expiration : null,
        insurance : "",
      },
    };
  },
  computed: {
    ...mapGetters(["token", "profile", "is_root"]),
    updating() {
      return !!this.editingItem._id;
    },
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.state.loading = val;
      }
    },
  },
  methods: {
    ...mapActions([
      "sendSuccess",
      "sendError",
      "requestConfirm",
      "logout",
      "load",
      "delete",
      "get",
      "update",
      "insert"
    ]),
    cardExpirationClass( date ) {
      if(utils.toDateTime(date) < DateTime.now()) {
        return "body-1 error--text";
      }
      return "body-1 success--text";
    },
    formatDate : utils.formatDate,
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({ collection: "cards", id: id + "?display=true" })
        .then(reply => {
          this.loading = false;
          if (reply.data) {
            this.card = reply.data;
          }
        })
        .catch(err => {
          this.loading = false;
          let message = err.message
            ? err.message
            : "errore al caricamento dei dati della tessera";
          this.sendError({ message });
          this.$router.go(-1);
          if (err.statusCode === 401) {
            this.logout();
          }
        });
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<style scoped></style>
