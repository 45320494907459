<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Informazione vendità</h2>
            <v-spacer />
            <v-btn text color="accent" :disabled="item.block_edit" @click="$router.replace('/sells/' + $route.params.id )">Modifica</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span class="fw-6 mr-3">Utente</span> <a :href="'/#/users/' + item.user._id" >{{item.user.surname}} {{item.user.name}}</a>
                </v-col>
                <v-col cols="12" class="title fw-3">
                  <span class="fw-6">Prodotti nell'ordine:</span>
                  <ul>
                    <li v-for="item in item.items"><a :href="'/#/kits/' + item.kit._id" >{{item.kit.title.it}}</a> - <a :href="'/#/products/' + item._id" >{{item.title.it}}</a></li>
                  </ul>
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span class="fw-6 mr-3">Prezzo totale</span> € {{total}}
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span class="fw-6 mr-3">Data creazione ordine</span> {{formatDate(item.creation_date)}}
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span class="fw-6 mr-3">Data ultima modifica ordine</span> {{formatDate(item.last_update)}}
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span class="fw-6 mr-3">Tipo di pagamento</span> {{formatPaymentMethod(item.payment_method)}}
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span class="fw-6 mr-3">N° ricevuta / N° fattura / ID di pagamento</span> {{item.payment_uid}}
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span class="fw-6 mr-3">Con spedizione</span> <span v-if="item.expedition">Sì</span><span v-else>No</span>
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span class="fw-6 mr-3">Con Home Kit</span> <span v-if="item.home_kit">Sì</span><span v-else>No</span>
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span class="fw-6 mr-3">Con tessera (nuova o rinnovo)</span> <span v-if="item.card">Sì</span><span v-else>No</span>
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3" v-if="item.expedition">
                  <span class="fw-6 mr-3">Indirizzo di spedizione</span> {{item.address.name}}, {{item.address.city}} ({{item.address.province}}), {{item.address.zip_code}}
                </v-col>
                <v-col cols="12" class="d-flex align-center title fw-3">
                  <span v-if="item.evaded" class="fw-6 mr-3 success--text font-weight-bold">ORDINE EVASO</span>
                  <v-btn color="error" v-else rounded @click="evadiOrdine()">EVADI ORDINE</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" :disabled="item.block_edit" @click="$router.replace('/sells/' + $route.params.id )">Modifica</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  import {DateTime} from "luxon";
  export default {
    name: "DifficultyForm",
    data () {
      return {
        kit_id : null,
        item : {
          user : {
            name : '',
            surname : ''
          },
          items : [],
          expedition : false,
          payment_method : 'manual',
          payment_uid : '',
          status : 'draft',
          creation_date : new Date(),
          last_update : new Date(),
          address: {
            name: "",
            city: "",
            province: "",
            zip_code: ""
          },
          block_edit : false,
          evaded : false,
        },
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root']),
      total() {
        let price = 0;
        for(let i = 0; i < this.item.items.length; i++) {
          price +=  this.item.items[i].price;
        }
        return price;
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load','get', 'refreshCollection','requestConfirm']),
      formatDate( date ) {
        return DateTime.fromISO(date).toFormat("dd/MM/yyyy");
      },
      formatPaymentMethod( method ) {
        if(method === 'online') return 'Pagamento online';
        return 'Pagamento a mano/in palestra';
      },
      evadiOrdine() {
        this.requestConfirm({ title : 'Richiesta evasione ordine', message : "Sei sicuro di voler evadere quest'ordine?", callback: ( accepted )=>{
            if(accepted) {
              let id = this.$route.params.id;
              this.get({ collection : 'carts', id : id + "/evaded" }).then(()=>{
                this.loadData();
                this.sendSuccess({message:'Ordine evaso!'});
              }).catch((err)=>{
                let message = err.message ? err.message : "errore alla evasione dell'ordine";
                this.sendError({ message });
                if(config.debug) console.error("errore alla evasione dell'ordine",err);
                if(err.statusCode === 401) {
                  this.logout();
                }
              })

            }
          }});
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection: 'carts', id: id + '?display=true'}).then((reply) => {
          this.loading = false;
          if (reply.data) {
            this.item = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati della vendita';
          this.sendError({message});
          this.$router.go(-1);
          if (err.statusCode === 401) {
            this.logout();
          }
        })
      }
    },
    mounted() {
      this.loadData();
    }
  }
</script>

<style scoped></style>
