<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
            <v-btn text color="accent" @click="save(4)">Salva e aggiungi lezioni</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <h2 class="title"><span class="font-weight-bold">Pacchetto:</span> {{kit.title.it}}</h2>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Titolo*"
                          rounded filled
                          v-model="editingItem.title.it"
                          :error="titleError"
                          :error-messages="requiredErrorMessages(titleError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Codice Prodotto (SKU)*"
                          rounded filled
                          v-model="editingItem.sku"
                          hint="codice univoco prodotto per il sistema di vendita. es: SKU00001, M4KIT00001, ABC12345, 01234XYZ, ecc."
                          persistent-hint
                          :error="skuError"
                          :error-messages="requiredErrorMessages(skuError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                      label="Tipologia Prodotto*"
                      rounded filled
                      v-model="editingItem.type"
                      :items="productTypeOptions"
                      :error="typeError"
                      :error-messages="requiredErrorMessages(typeError)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Prezzo*"
                          rounded filled
                          :step="0.01"
                          type="number"
                          prefix="€"
                          :hint="ivaPrice"
                          persistent-hint
                          v-model="editingItem.price"
                          :error="priceError"
                          :error-messages="requiredErrorMessages(priceError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Prezzo al riaquisto"
                      rounded filled
                      :step="0.01"
                      type="number"
                      prefix="€"
                      :hint="ivaSecondPrice"
                      persistent-hint
                      v-model="editingItem.second_price"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-textarea
                          label="Descrizione*"
                          rounded filled
                          v-model="editingItem.description.it"
                          :error="descError"
                          :error-messages="requiredErrorMessages(descError)"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="editingItem.type === productType.video">
                  <v-text-field
                          label="Intervallo giorni tra una lezione e l'altra*"
                          rounded filled
                          :step="1"
                          type="number"
                          v-model="editingItem.days_interval"
                          :error="daysError"
                          :error-messages="requiredErrorMessages(daysError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Per questo prodotto è prevista una spedizione"
                          hint="es. kit in omaggio o altro"
                          persistent-hint
                          v-model="editingItem.expedition"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row v-if="editingItem.type !== productType.video">
                <v-col cols="12">
                  <v-divider />
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select rounded filled label="Sottocategoria prodotto" :items="subcategoriesOptions" v-model="editingItem.subcategory_id"
                            :error="subCatError" :error-messages="requiredErrorMessages(subCatError)"></v-select>
                </v-col>


                <v-col cols="12" md="6" lg="4" xl="3" v-if="editingItem.type === productType.mixed">
                  <v-select rounded filled label="Tipologia sedute" :items="seatsTypeOptions" v-model="editingItem.seats_types" clearable
                            :error="seatsTypeError" :error-messages="requiredErrorMessages(seatsTypeError)" multiple chips deletable-chips></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="editingItem.type === productType.mixed">
                  <div class="d-flex fill-height align-center">
                    <v-switch v-model="editingItem.consumable" class="mt-0"></v-switch>
                    <v-text-field rounded filled label="N° sedute" v-model="editingItem.num_seats" type="number" :disabled="!editingItem.consumable"
                                  :error="seatsNumError" :error-messages="nonZeroErrorMessages(seatsNumError)">
                    </v-text-field>
                  </div>

                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="editingItem.type === productType.mixed">
                  <v-text-field rounded filled label="Durata pacchetto" v-model="editingItem.seats_duration" type="number"
                   hint="durata in giorni delle sedute dal primo utilizzo" persistent-hint
                                :error="seatsDurationError" :error-messages="nonZeroErrorMessages(seatsDurationError)"></v-text-field>
                </v-col>

                <v-col cols="12"  v-if="editingItem.type === productType.distinct">
                  <v-card class="mb-4">
                    <v-card-title><h4>Pacchetti sedute</h4></v-card-title>
                    <v-card-text>
                      <v-simple-table >
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-center" style="width: 100px">Chi Comanda?</th>
                            <th class="text-left">Tipologia sedute</th>
                            <th class="text-left">A consumo?</th>
                            <th class="text-left">N° sedute</th>
                            <th class="text-left">Durata (giorni)</th>
                            <th class="text-center"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-if="!editingItem.seats || !editingItem.seats.length">
                            <td colspan="6" class="font-italic">Non ci sono sedute inserite</td>
                          </tr>
                          <tr v-for="(item,i) in editingItem.seats" :key="`seats_${i}`">
                            <td>
                              <div class="d-flex justify-center full-width ma-0">
                                <v-checkbox v-model="item.main" class="ma-0" hide-details @change="onMainSeatsChange(item, i)"></v-checkbox>
                              </div>

                            </td>
                            <td>
                              <v-chip-group v-if="item.seats_types">
                                <v-chip small v-for="(s,j) in item.seats_types" :key="`seats_type_${i}_${j}`">{{getSeatsName(s)}}</v-chip>
                              </v-chip-group>
                            </td>
                            <td><strong v-if="item.consumable">Sì</strong><span v-else>No</span></td>
                            <td>{{ item.consumable ? item.num_seats : '-'}}</td>
                            <td>{{ item.duration }}</td>
                            <td><v-btn text icon color="error" @click="removeSeat(i)"><v-icon>mdi-close</v-icon></v-btn></td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>


                  <v-form ref="addSeatForm" @submit.prevent="addSeat()">
                    <v-row>
                      <v-col cols="12"><h2>Aggiungi un pacchetto sedute</h2></v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-select rounded filled label="Tipologia sedute" :items="seatsTypeOptions" v-model="newSeat.seats_types"
                                  :rules="[rules.required]" multiple chips deletable-chips clearable></v-select>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <div class="d-flex fill-height align-center">
                          <v-switch v-model="newSeat.consumable" class="mt-0"></v-switch>
                          <v-text-field rounded filled label="N° sedute" v-model="newSeat.num_seats" type="number" :disabled="!newSeat.consumable"
                                        :rules="newSeat.consumable ? [rules.required] : []">
                          </v-text-field>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-text-field rounded filled label="Durata sedute" v-model="newSeat.duration" type="number" step="1" min="1"
                                      hint="durata in giorni delle sedute dal primo utilizzo" persistent-hint :rules="[rules.required, rules.minInt(1)]"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-btn small rounded color="accent" type="submit">Aggiungi seduta</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-row>
                    <v-col cols="12" md="6" lg="4" xl="3">
                      <v-checkbox label="Le sedute hanno una scadenza condivisa" v-model="editingItem.seats_has_same_expiration"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
            <v-btn text color="accent" @click="save(4)">Salva e aggiungi lezioni</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  import {productType, productTypeOptions, seatsTypes} from "../consts";
  import rules from "../utils/rules";
  export default {
    name: "ProductForm",
    data () {
      return {
        productType,
        productTypeOptions,
        newSeat : {
          seats_types : [],
          num_seats : 0,
          consumable : false,
          duration : 0,
          main : true,
        },
        editingItem : {
          title : { it : ''},
          description : { it : ''},
          price : 0,
          second_price : 0,
          days_interval: 1,
          sku : '',
          kit_id : null,
          expedition : false,
          is_seats_product : false,
          seats_has_same_expiration : false,
          type : productType.mixed,
          consumable : false,
          seats_duration : 0,
          seats_type : '',
          seats_types : [],
          num_seats : 0,
          subcategory_id : null,
          seats : []
        },
        kit: {
          title : {
            it : ''
          }
        },
        daysError : false,
        titleError : false,
        descError : false,
        skuError : false,
        typeError : false,
        priceError : false,
        seatsTypeOptions : [
          { text : 'TopGym Experience', value : 'topgymexperience'},
          { text : 'Sala Corsi', value : 'corsi'},
          { text : 'Open Gym', value : 'opengym'},
          { text : 'Vacu', value : 'vacu'},
          { text : 'One-to-One', value : 'onetoone'},
          { text : 'Bike indoor', value : 'gym'},
        ],
        subCatError : false,
        seatsTypeError : false,
        seatsNumError : false,
        seatsDurationError : false,
        rules,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root','subcategories']),
      updating() {
        return !!this.editingItem._id
      },
      subcategoriesOptions() {
        return this.subcategories.map(item=>{
          return { text : item.name.it, value : item._id}
        })
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuovo prodotto' : 'Modifica prodotto';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      },
      ivaPrice(){
        let price = utils.toFloat(this.editingItem.price, 0);
        const iva = price / 100 * config.iva;
        return "+ iva al "+config.iva+"% = " + (price + iva).toFixed(2) + "€";
      },
      ivaSecondPrice(){

        let price = utils.toFloat(this.editingItem.second_price, 0);
        if(!price) {
          return "lascia a zero se non vuoi un che abbia un secondo prezzo al riacquisto"
        }
        const iva = price / 100 * config.iva;
        return "+ iva al "+config.iva+"% = " + (price + iva).toFixed(2) + "€";
      }
    },
    methods : {
      ...mapActions(['refreshCollection', 'sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert']),
      onMainSeatsChange(item, i) {
        if(item.main) {
          for(let j = 0; j < this.editingItem.seats.length; j++) {
            this.editingItem.seats[j].main = i === j;
          }
        } else {
          if(i === 0 && this.editingItem.seats.length >= 2) {
            this.editingItem.seats[1].main = true
          }
          if(i > 0) {
            this.editingItem.seats[0].main = true
          }
        }


      },
      getSeatsName(s) {
        /*
        <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('topgymexperience')">TopGym Experience</v-chip>
                      <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('opengym')">Open Gym</v-chip>
                      <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('corsi')">Sala Corsi</v-chip>
                      <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('onetoone')">One-to-One</v-chip>
                      <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('gym')">Ginnastica e Corpo Libero</v-chip>
                      <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('vacu')">Vacu</v-chip>
         */
        switch (s) {
          case seatsTypes.topgymexperience:
            return "Top Gym Experience"
          case seatsTypes.opengym:
            return "Open Gym"
          case seatsTypes.corsi:
            return "Sala corsi"
          case seatsTypes.gym:
            return "Bike indoor"
          case seatsTypes.vacu:
            return "Vacu"
        }
        return s
      },
      removeSeat(i) {
        this.requestConfirm({ title : 'Richiesta clonazione', message : 'Sei sicuro di voler eliminare questo elemento?', callback: ( accepted )=>{
            if(accepted) {
              this.editingItem.seats.splice(i,1)
            }
          }});
      },
      addSeat() {
        if(!this.$refs.addSeatForm.validate()) return
        this.newSeat.num_seats = utils.toInt(this.newSeat.num_seats, 0)
        this.newSeat.duration = utils.toInt(this.newSeat.duration, 1)
        if(!this.editingItem.seats) this.editingItem.seats = []
        if( this.editingItem.seats.filter(s => s.main).length === 0) {
          this.newSeat.main = true;
        }

        this.editingItem.seats.push({...this.newSeat})

        this.newSeat = {
          seats_types : [],
          num_seats : 0,
          consumable : false,
          duration : 0,
          main : false
        }

      },
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      nonZeroErrorMessages(err) {
        if(err) return ["Inserisci un valore maggiore di zero"];
        return []
      },
      loadKitData() {
        let id = this.$route.params.kitId;
        this.get({collection : 'kits', id}).then((reply)=>{
          if(reply.data) {
            this.kit = reply.data;
            this.editingItem.kit_id = reply.data._id;
          }
        }).catch((err) => {
          let message = err.message ? err.message : 'errore al caricamento dei dati del prodotto';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'products', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            Object.assign(this.editingItem, reply.data);
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati del prodotto';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.titleError = false;
        this.descError = false;
        this.skuError = false;
        this.priceError = false;
        this.daysError = false;
        this.seatsTypeError = false;
        this.seatsNumError = false;
        this.seatsDurationError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;
        if(!this.editingItem.title.it) {
          this.titleError = true;
          return;
        }
        if(!this.editingItem.description.it) {
          this.descError = true;
          return;
        }
        if(!this.editingItem.sku) {
          this.skuError = true;
          return;
        }
        if(!this.editingItem.price) {
          this.priceError = true;
          return;
        }

        this.editingItem.price = utils.toFloat(this.editingItem.price, 0);
        if(!this.editingItem.price) {
          this.priceError = true;
          return;
        }
        if(this.editingItem.type === productType.video) {
          this.editingItem.days_interval = utils.toInt(this.editingItem.days_interval, 0);
          if(!this.editingItem.days_interval) {
            this.daysError = true;
            return;
          }
        }


        this.editingItem.second_price = utils.toFloat(this.editingItem.second_price, 0);

        if(this.editingItem.type === productType.mixed) {
          if(!this.editingItem.seats_types || !this.editingItem.seats_types.length) {
            this.seatsTypeError = true;
            return;
          }

          if(this.editingItem.consumable) {
            this.editingItem.num_seats = utils.toInt(this.editingItem.num_seats, 0)
            if(!this.editingItem.num_seats) {
              this.seatsNumError = true;
              return;
            }
          } else {
            this.editingItem.num_seats = 0;
          }


          this.editingItem.seats_duration = utils.toInt(this.editingItem.seats_duration, 0)
          if(!this.editingItem.seats_duration) {
            this.seatsDurationError = true;
            return;
          }
        }

        if(this.editingItem.type === productType.distinct && (!this.editingItem.seats || !this.editingItem.seats.length)) {
          this.sendError({message:'Inserisci almeno un pacchetto sedute!'})
          return;
        }

        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'products', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Prodotto salvato"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/kits/" + this.kit._id + "/products");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/kits/" + this.kit._id + "/products/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  title : { it : ''},
                  description : { it : ''},
                  price : 0,
                  second_price : 0,
                  days_interval : 1,
                  sku : '',
                  kit_id : this.kit._id,
                  expedition : false,
                  is_seats_product : false,
                  consumable : false,
                  seats_duration : 0,
                  seats_type : '',
                  seats_types : [],
                  num_seats : 0,
                  type : productType.mixed,
                  seats:  []
                };
              } else {
                this.$router.replace("/kits/" + this.kit._id + "/products/new");
              }
              break;
            case 4:
              // new
              this.$router.replace({ name : 'product lessons', params : { kitId : reply.data.kit_id, id : reply.data._id }});
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save product error:", err);
          let message = err.message ? err.message : "errore al salvataggio del prodotto";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      this.refreshCollection({collection:'subcategories'});
      this.loadKitData();
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>
