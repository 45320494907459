<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <h2 class="title"><span class="font-weight-bold">Prodotto:</span> {{product.title.it}}</h2>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                          label="Video esplicativi*"
                          rounded filled
                          v-model="editingItem.video_id"
                          :items="videoOptions"
                          :error="videoError"
                          :error-messages="requiredErrorMessages(videoError)"
                          prepend-icon="mdi-cloud-upload"
                          @click:prepend="openUploadDialog()"
                  ></v-autocomplete>
                  <v-responsive :aspect-ratio="16/9" style="background: #000">
                    <video v-if="editingItem.video_id" :src="selectedVideoUrl"  style="width: 100%; height: 100%;" controls/>
                  </v-responsive>

                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Titolo*"
                          rounded filled
                          v-model="editingItem.title.it"
                          :error="titleError"
                          :error-messages="requiredErrorMessages(titleError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-textarea
                          label="Descrizione"
                          rounded filled
                          v-model="editingItem.description.it"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Android video mode"
                          rounded filled
                          v-model="editingItem.android_video_mode"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col cols="12">
                  <h4 class="title">Sessioni di esercizio nella lezione:</h4>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="!multipleScheduleSelected">
                  <v-autocomplete
                          label="Esercizio*"
                          rounded filled
                          v-model="editingSchedule.exercise_id"
                          hide-details
                          :items="exerciseOptions"
                          :error="exerciseError"
                          :error-messages="requiredErrorMessages(exerciseError)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="!multipleScheduleSelected">
                  <v-text-field
                          label="Tempo di lavoro*"
                          rounded filled
                          :step="1"
                          type="number"
                          v-model="editingSchedule.work_time"
                          hide-details
                          :error="workError"
                          :error-messages="requiredErrorMessages(workError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="!multipleScheduleSelected">
                  <v-text-field
                          label="Tempo di recupero*"
                          rounded filled
                          :step="1"
                          type="number"
                          v-model="editingSchedule.recovery_time"
                          hide-details
                          :error="recoverError"
                          :error-messages="requiredErrorMessages(recoverError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" class="d-flex align-center" v-if="!multipleScheduleSelected">
                  <v-btn color="accent" dark rounded filled rounded v-on:click="addSchedule()">
                    <span v-if="editingScheduleIndex < 0">Inserisci</span>
                    <span v-else>Salva</span>
                    <v-icon v-if="editingScheduleIndex < 0" dark>mdi-plus</v-icon>
                    <v-icon v-else dark>mdi-content-save</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12" v-if="multipleScheduleSelected">
                  <v-btn color="accent" dark rounded filled rounded v-on:click="removeScheduleMultiple()" class="flex-shrink-1 flex-grow-0">
                    Elimina multiplo
                  </v-btn>
                  <v-btn color="accent" dark rounded filled rounded v-on:click="multipleEditForm = true"  class="ml-3 flex-shrink-1 flex-grow-0">
                    Modifica multiplo
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-simple-table :key="tableRefresh">
                    <thead>
                    <tr>
                      <th class="text-center"><v-checkbox v-model="selectAll" @change="selectAllChange"></v-checkbox></th>
                      <th class="text-left">Esercizio</th>
                      <th class="text-left">Tempo di lavoro (sec)</th>
                      <th class="text-left">Tempo di recupero (sec)</th>
                      <th class="text-center" style="width: 60px">Clona</th>
                      <th class="text-center" style="width: 60px">Modifica</th>
                      <th class="text-center" style="width: 60px">Rimuovi</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, i) in editingItem.schedules" :key="i" :class="i === editingScheduleIndex ? 'warning' : ''">
                      <td><v-checkbox v-model="selectedIndexes[i]" @change="editingScheduleIndex = -1;"></v-checkbox></td>
                      <td>{{ getExerciseName(item.exercise_id) }}</td>
                      <td>{{ item.work_time }}</td>
                      <td>{{ item.recovery_time }}</td>
                      <td>
                        <v-btn color="accent" dark text icon v-on:click="cloneSchedule(i)">
                          <v-icon dark>mdi-content-copy</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="accent" dark text icon v-on:click="editSchedule(i)">
                          <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="error" dark text icon v-on:click="requestScheduleDelete(i)">
                          <v-icon dark>mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


    <v-dialog v-model="uploadDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Carica video</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                        label="Nome*"
                        rounded filled
                        v-model="uploadMedia.name"
                        :error="mediaNameError"
                        :error-messages="requiredErrorMessages(mediaNameError)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input v-model="file" chips rounded filled show-size
                              accept=".mp4" label="File"
                              placeholder="Clicca qui per scegliere il file"></v-file-input>
              </v-col>
              <v-col cols="12" >
                <v-text-field
                        label="Url"
                        rounded filled
                        persistent-hint
                        hint="In alternativa al file upload"
                        v-model="uploadMedia.url"
                        :disabled="file != null"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="uploading" @click="uploadDialog = false">Annulla</v-btn>
          <v-btn color="error" :disabled="uploading" :loading="uploading" text @click="beginUpload()">Inserisci</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="multipleEditForm" persistent max-width="300">
      <v-card>
        <v-card-title class="headline">Modifica multipla</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" >
                <v-checkbox v-model="enableFormExercise" label="Abilita"></v-checkbox>
                <v-autocomplete
                    label="Esercizio"
                    rounded filled
                    :disabled="!enableFormExercise"
                    v-model="formExerciseId"
                    hide-details
                    :items="exerciseOptions"
                    :error="exerciseError"
                    :error-messages="requiredErrorMessages(exerciseError)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="enableFormWorkTime" label="Abilita"></v-checkbox>
                <v-text-field
                    :disabled="!enableFormWorkTime"
                    label="Tempo di lavoro"
                    rounded filled
                    :step="1"
                    type="number"
                    v-model="formWorkTime"
                    hide-details
                    :error="workError"
                    :error-messages="requiredErrorMessages(workError)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="enableFormRecoveryTime" label="Abilita"></v-checkbox>
                <v-text-field
                    :disabled="!enableFormRecoveryTime"
                    label="Tempo di recupero"
                    rounded filled
                    :step="1"
                    type="number"
                    v-model="formRecoveryTime"
                    hide-details
                    :error="recoverError"
                    :error-messages="requiredErrorMessages(recoverError)"
                ></v-text-field>
              </v-col>
              
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="multipleEditForm = false">Annulla</v-btn>
          <v-btn color="error" text @click="editMultiple()">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  export default {
    name: "LessonForm",
    data () {
      return {
        multipleEditForm : false,
        tableRefresh : 0,
        editingItem : {
          title : { it : ''},
          description : { it : ''},
          video_id : null,
          product_id : null,
          schedules: [],
          android_video_mode : false
        },
        editingScheduleIndex : -1,
        editingSchedule : {
          exercise_id : null,
          work_time : 0,
          recovery_time : 0,
        },
        enableFormExercise : false,
        enableFormWorkTime : false,
        enableFormRecoveryTime : false,
        formExerciseId : null,
        formWorkTime : 0,
        formRecoveryTime : 0,
        selectAll : false,
        selectedIndexes : [],
        product: {
          _id: null,
          title : {
            it : ''
          }
        },
        file : null,
        uploading : false,
        uploadDialog : false,
        uploadMedia: {
          name : '',
          media : 'icon',
          url : '',
        },
        mediaNameError : false,
        titleError : false,
        videoError : false,
        exerciseError : false,
        workError : false,
        recoverError : false,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root','media','exercises']),
      updating() {
        return !!this.editingItem._id
      },
      multipleScheduleSelected(){
        let filter = this.selectedIndexes.filter((item) => item === true)
        return filter.length > 0;
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuova lezione' : 'Modifica lezione';
      },
      exerciseOptions() {
        return this.exercises.map((item)=>{
          return { text : item.title.it, value: item._id }
        })
      },
      videoOptions() {
        return this.media.filter((item) => {
          return item.media === 'video'
        }).map((item)=>{
          return { text : item.name, value: item._id }
        })
      },
      selectedVideoUrl() {
        let media = this.getMedia(this.editingItem.video_id);
        if(!media) return "";
        return media.url || "";
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection','upload']),
      selectAllChange() {
        this.selectedIndexes = Array.from({length: this.editingItem.schedules.length}, (v, k) => this.selectAll);
        this.editingScheduleIndex = -1;
        this.tableRefresh++;
      },
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      openUploadDialog() {
        this.uploadMedia.media = 'video';
        this.uploadDialog = true;
        this.uploading = false;
      },
      beginUpload() {
        this.mediaNameError = false;
        if(!this.uploadMedia.name) {
          this.mediaNameError = true;
          return
        }
        this.uploading = true;
        this.insert({collection:"media", data: this.uploadMedia}).then((reply)=>{
          if(reply.data) {
            this.editingItem.video_id = reply.data._id;
            if(this.file) {
              let formData = new FormData();
              formData.append("media", this.file);
              this.upload({ where : 'media/' + reply.data._id + '/upload', formData }).then(() => {
                this.refreshCollection({collection: 'media'});
                this.uploading = false;
                this.uploadDialog = false;
                this.file = null;
                this.uploadMedia.name = '';
                this.uploadMedia.url = '';
              }).catch(onError);
              return
            }
            this.refreshCollection({collection: 'media'});
            this.sendSuccess({message: "Media inserito."})
          } else {
            this.sendError({message : 'errore all\'inserimento del media'});
          }
          this.uploading = false;
          this.uploadDialog = false;
        }).catch(onError);

        let onError = (err) => {
          this.uploading = false;
          let message = err.message ? err.message : 'errore all\'inserimento del media';
          this.sendError({message});
          if(err.statusCode === 401) {
            this.logout();
          }
        }
      },
      getMedia( id ) {
        for(let i = 0; i < this.media.length; i++) {
          if(this.media[i]._id === id) {
            return this.media[i];
          }
        }
        return null
      },
      getExerciseName( id ) {
        for(let i = 0; i < this.exercises.length; i++) {
          if(this.exercises[i]._id === id) {
            return this.exercises[i].title.it;
          }
        }
        return null
      },
      removeScheduleMultiple() {
        this.requestConfirm({title: "Richiesta conferma", message: "Sei sicuro di voler eiminare gli esercizi selezionati?", callback : ( ok ) =>{
          if(ok) {
            this.editingItem.schedules = this.editingItem.schedules.filter((item, i) => {
              return !this.selectedIndexes[i];
            })
            this.selectAll = false;
            this.selectedIndexes = Array.from({length: this.editingItem.schedules.length}, (v, k) => false);
            this.tableRefresh++;
          }
        }});

      },
      editMultiple() {
        this.exerciseError = false;
        this.workError = false;
        this.recoverError = false;

        if(this.enableFormExercise && !this.formExerciseId) {
          this.exerciseError = true;
          return
        }

        if(this.enableFormWorkTime) {
          this.formWorkTime = parseInt(this.formWorkTime, 10);
          if(_.isNaN(this.formWorkTime)) {
            this.workError = true;
            return
          }
        }
        if(this.enableFormRecoveryTime) {
          this.formRecoveryTime = parseInt(this.formRecoveryTime, 10);
          if(_.isNaN(this.formRecoveryTime)) {
            this.recoverError = true;
            return
          }
        }

        this.editingItem.schedules = this.editingItem.schedules.map((item, i) => {
          if(!this.selectedIndexes[i]) {
            return item;
          }

          if(this.enableFormExercise) {
            item.exercise_id = this.formExerciseId;
          }
          if(this.enableFormWorkTime) {
            item.work_time = this.formWorkTime;
          }
          if(this.enableFormRecoveryTime) {
            item.recovery_time = this.formRecoveryTime;
          }
          return item;

        });
        this.multipleEditForm = false;
      },
      addSchedule() {
        this.exerciseError = false;
        this.workError = false;
        this.recoverError = false;
        if(!this.editingSchedule.exercise_id) {
          this.exerciseError = true;
          return
        }

        this.editingSchedule.work_time = parseInt(this.editingSchedule.work_time, 10);
        if(_.isNaN(this.editingSchedule.work_time)) {
          this.workError = true;
          return
        }
        this.editingSchedule.recovery_time = parseInt(this.editingSchedule.recovery_time, 10);
        if(_.isNaN(this.editingSchedule.recovery_time)) {
          this.recoverError = true;
          return
        }


        if(this.editingScheduleIndex >= 0) {
          this.editingItem.schedules[this.editingScheduleIndex] = _.cloneDeep(this.editingSchedule);
        } else {
          this.editingItem.schedules.push(_.cloneDeep(this.editingSchedule));
        }
        this.editingScheduleIndex = -1;
      },
      editSchedule( i ) {
        this.selectAll = false
        this.selectedIndexes = Array.from({length: this.editingItem.schedules.length}, (v, k) => false);
        this.tableRefresh++;
        this.editingScheduleIndex = i;
        this.editingSchedule = _.cloneDeep(this.editingItem.schedules[i]);
      },
      cloneSchedule( i ) {
        this.editingScheduleIndex = -1;
        this.editingSchedule = _.cloneDeep(this.editingItem.schedules[i]);
        this.addSchedule();
      },
      requestScheduleDelete( i ) {
        this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questa sessione di esercizio?', callback: ( accepted )=>{
            if(accepted) {
              this.editingItem.schedules.splice(i, 1);
            }
          }});
      },
      loadProductData() {
        let id = this.$route.params.productId;
        this.get({collection : 'products', id}).then((reply)=>{
          if(reply.data) {
            this.product = reply.data;
            this.editingItem.product_id = reply.data._id;
          }
        }).catch((err) => {
          let message = err.message ? err.message : 'errore al caricamento dei dati della lezione';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'lessons', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            if(!reply.data.description) {
              reply.data.description = { it : '' }
            }
            this.selectedIndexes = Array.from({length: this.editingItem.schedules.length}, (v, k) => false);
            Object.assign(this.editingItem, reply.data);
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati della lezione';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.titleError = false;
        this.videoError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;
        if(!this.editingItem.title.it) {
          this.titleError = true;
          return;
        }
        if(!this.editingItem.video_id) {
          this.videoError = true;
          return;
        }

        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'lessons', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Lezione salvata"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/kits/" + this.$route.params.kitId + "/products/" + this.product._id + "/lessons");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/kits/" + this.$route.params.kitId + "/products/" + this.product._id + "/lessons/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  title : { it : ''},
                  description : { it : ''},
                  video_id : null,
                  product_id : this.product._id,
                  days_interval : 1,
                  schedules : [],
                  android_video_mode: false

                };
              } else {
                this.$router.replace("/kits/" + this.$route.params.kitId + "/products/" + this.product._id + "/lessons/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save lesson error:", err);
          let message = err.message ? err.message : "errore al salvataggio della lezione";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      this.refreshCollection({collection:"media"});
      this.refreshCollection({collection:"exercises"});
      this.loadProductData();
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>
