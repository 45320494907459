<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <h2 class="title">Dettaglio acquirente</h2>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                          label="Utente*"
                          rounded filled
                          :items="userOptions"
                          v-model="editingItem.user_id"
                          @change="onUserSelected"
                          :error="userError"
                          :error-messages="requiredErrorMessages(userError)"
                  >

                    <template v-slot:selection="data">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.fiscal_code"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.fiscal_code"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3" v-if="expedition">
                  <v-text-field
                          label="Indirizzo"
                          rounded filled
                          v-model="editingItem.address.name"
                          hint="Via\Piazza ..."
                          :error="addressError"
                          :error-messages="requiredErrorMessages(addressError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="8" md="4" lg="3" xl="2" v-if="expedition">
                  <v-text-field
                          label="Città"
                          rounded filled
                          v-model="editingItem.address.city"
                          :error="cityError"
                          :error-messages="requiredErrorMessages(cityError)"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="2" lg="2" xl="1" v-if="expedition">
                  <v-text-field
                          label="Provincia"
                          rounded filled
                          v-model="editingItem.address.province"
                          hint="es. PG"
                          :error="provError"
                          :error-messages="requiredErrorMessages(provError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="expedition">
                  <v-text-field
                          label="CAP"
                          rounded filled
                          v-model="editingItem.address.zip_code"
                          :error="capError"
                          :error-messages="requiredErrorMessages(capError)"
                  ></v-text-field>
                </v-col>

              </v-row>
              <v-divider />
              <v-row>
                <v-col cols="12">
                  <h2 class="title">Prodotti nell'ordine di acquisto</h2>
                  <span v-if="itemsError" class="error--text body-2">Devi inserire almeno un prodotto nell'ordine</span>
                </v-col>
                <v-col>
                  <ul>
                    <li v-for="(id, i) in editingItem.items" :key="id">{{productName(id)}} <v-btn icon color="accent" @click="removeProduct(i)"><v-icon>mdi-close</v-icon></v-btn></li>
                  </ul>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                          label="Pacchetto*"
                          rounded filled
                          :items="kitOptions"
                          :disabled="!editingItem.user_id"
                          v-model="kit_id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                          label="Prodotto*"
                          rounded filled
                          :disabled="!kit_id || !editingItem.user_id"
                          :items="productOptions"
                          v-model="selectedProductId"
                          append-outer-icon="mdi-plus"
                          @click:append-outer="addProduct()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12">
                  <h2 class="title">Giorni di pausa abbonamento</h2>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Numero di giorni di pausa extra"
                      rounded filled
                      v-model="editingItem.sub_pause_days"
                      type="number"
                      step="1"
                      min="0"
                      max="20"
                      :error="subPauseError"
                      :error-messages="subPauseErrorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col cols="12">
                  <h2 class="title">Dettagli ordine</h2>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                          label="Metodo di pagamento"
                          rounded filled
                          :items="paymentMethods"
                          v-model="editingItem.payment_method"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="ID di pagamento"
                          rounded filled
                          v-model="editingItem.payment_uid"
                          :error="puidError"
                          :error-messages="requiredErrorMessages(puidError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                          label="Stato ordine"
                          rounded filled
                          :items="statusOptions"
                          v-model="editingItem.status"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Home Kit (in regalo o nell'ordine)"
                          rounded filled
                          v-model="editingItem.home_kit"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Richiesta tessera MSP (nuova o rinnovo)"
                          :hint="`La tessera ha un costo di ${settings.coni_card_price}€`"
                          persistent-hint
                          rounded filled
                          v-model="editingItem.card"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Ordine evaso"
                          rounded filled
                          v-model="editingItem.evaded"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Impediscine la modifica/cancellazione"
                          rounded filled
                          v-model="editingItem.block_edit"
                  ></v-switch>
                </v-col>

              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="cardDialog" max-width="500">
      <v-card>
        <v-card-title>Tessera MSP scaduta</v-card-title>
        <v-card-text>
          <p>La tessera MSP di questo utente è scaduta, vuoi aggiungere il rinnovo della tessera al carrello?<br>La tessera ha un costo di {{settings.coni_card_price}}€</p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cardDialog = false;">No</v-btn>
          <v-btn text color="accent" @click="addConiCardToCart()">Sì</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  import {DateTime} from "luxon";
  export default {
    name: "PurchaseForm",
    data () {
      return {
        kit_id : null,
        selectedProductId : null,
        userPurchases : [],
        settings: {
          coni_card_price : 15,
          max_days_of_subscription_pause : 10,
          extra_day_of_sub_pause_price : 3
        },
        editingItem : {
          user_id : null,
          items : [],
          expedition : false,
          sub_pause_days : 0,
          payment_method : 'manual',
          payment_uid : '',
          status : 'draft',
          address: {
            name: "",
            city: "",
            province: "",
            zip_code: ""
          },
          block_edit : false,
          home_kit : false,
          card : false,
          evaded : false,
        },
        paymentMethods: config.paymentMethods,
        userError : false,
        addressError : false,
        cityError : false,
        provError : false,
        capError : false,
        itemsError : false,
        puidError : false,
        cardDialog : false,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root','kits','users','products','cards','pauses']),
      updating() {
        return !!this.editingItem._id
      },
      statusOptions() {
        if(!this.editingItem.expedition) {
          return config.cartStatusOptions.filter((item)=>{
            return item.value !== 'paid'
          })
        }
        return config.cartStatusOptions
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuova vendita' : 'Modifica vendita';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      },
      userOptions() {
        return this.users.map((item)=>{
          return { text:item.surname + ' ' + item.name, fiscal_code: item.fiscal_code, value: item._id };
        });
      },
      kitOptions() {
        return this.kits.map((item)=>{
          return { text:item.title.it + " - " + item.genre.name.it, value: item._id };
        });
      },
      productOptions() {
        return this.products.filter((item)=>{
          if(!this.kit_id) return false
          if(this.editingItem.items.includes(item._id)) return false
          if(this.userPurchases.map((pur) => pur.product_id).includes(item._id)) return false;
          return this.kit_id === item.kit._id;
        }).map((item)=>{
          return { text:item.title.it + "(" + item.sku + ")", value: item._id };
        });
      },
      subPauseErrorMessages() {
        const days = utils.toInt(this.editingItem.sub_pause_days, 0)
        if(days < 0) {
          return ['inserisci un numero maggiore di zero']
        }
        if(days + this.subPauseExtraAvailable > 20) {
          return ["non puoi acquistare più di 20 giorni di pausa durante l'anno sportivo"]
        }
        return []
      },
      subPauseError() {
        return this.subPauseErrorMessages.length > 0
      },
      subPauseExtraAvailable() {
        const user = utils.getObjectInArray(this.users, '_id', this.editingItem.user_id)
        if(!user) return 0
        const now = DateTime.now()
        const pauses = this.pauses.filter(p => {
          if(p.user_id !== user._id) return false;
          const from = DateTime.fromISO(p.from)
          const to = DateTime.fromISO(p.to)
          return now > from && now < to
        })
        let num = 0;
        for(let i = 0; i < pauses.length; i++) {
          const p = pauses[i]
          num+=p.num_days;
        }

        return Math.max(0, 20 - num);
        //this.settings.max_days_of_subscription_pause
        //this.settings.extra_day_of_sub_pause_price
      },
      expedition : {
        get() {
          return this.editingItem.expedition
        }
      },
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection']),
      loadSettings() {
        this.loading = true;
        this.get({collection : 'settings'}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.settings = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento delle impostazioni del calendario';
          this.sendError({message});
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      addProduct() {
        this.editingItem.items.push(this.selectedProductId)
        const p = this.getProduct(this.selectedProductId);
        if(p.expedition) {
          this.editingItem.expedition = true
        }
      },
      removeProduct( i ) {

        this.editingItem.items.splice(i, 1)
        this.editingItem.expedition = false
        for(let i = 0; i < this.editingItem.items.length; i++) {
          const p = this.getProduct(this.editingItem.items[i]);
          if(p.expedition) {
            this.editingItem.expedition = true
          }
        }
        if(this.editingItem.status === 'expedition' || this.editingItem.status === 'paid') {
          this.editingItem.status = 'closed'
        }
      },

      productName(id) {

        const p = this.getProduct(id);
        if(p) {
          return p.kit.title.it + " - " + p.title.it + ': ' + p.price + '€';
        }
        return '-';
      },
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      getProduct( id ) {
        for(let i = 0; i < this.products.length; i++) {
          const product = this.products[i];
          if(product._id === id) {
            return product;
          }
        }
        return null;
      },
      onUserSelected() {
        this.editingItem.card = false
        this.refreshPurchases();
        const cards = this.cards.filter(c => {
          if(c.user_id !== this.editingItem.user_id) return false
          const dt = DateTime.fromISO(c.expiration)
          return dt > DateTime.now()
        })
        if(!cards.length) {
          this.cardDialog = true
        }
      },
      addConiCardToCart() {
        this.editingItem.card = true
        this.cardDialog = false
      },
      refreshPurchases() {

        let filter = {
          query : { user : this.editingItem.user_id },
          limit : 0,
          page : 1, sort : null, order : null
        };

        this.load({ collection : 'purchases', filter}).then((reply)=>{
          if(reply.data) {
            this.userPurchases = reply.data;
          }
        }).catch((err)=>{
          this.tableLoading = false;
          this.sendError({message:"Errore al caricamento degli elementi acquistati da questo utente."});
          if(config.debug) console.error("Errore al caricamento degli elementi acquistati da questo utente: " + err);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'carts', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati della vendita';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.userError = false;
        this.cityError = false;
        this.provError = false;
        this.capError = false;
        this.itemsError = false;
        this.addressError = false;
        this.puidError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;

        this.editingItem.sub_pause_days = utils.toInt(this.editingItem.sub_pause_days, 0)
        if(!this.editingItem.user_id) {
          this.userError = true;
          return;
        }

        if(this.editingItem.expedition) {
          if(!this.editingItem.address || !this.editingItem.address.name) {
            this.addressError = true
            return;
          }
          if(!this.editingItem.address || !this.editingItem.address.city) {
            this.cityError = true
            return;
          }
          if(!this.editingItem.address || !this.editingItem.address.province) {
            this.provError = true
            return;
          }
          if(!this.editingItem.address || !this.editingItem.address.zip_code) {
            this.capError = true
            return;
          }
        } else {
          this.editingItem.address = undefined
        }

        if(this.editingItem.status !== 'draft' && !this.editingItem.items.length) {
          this.itemsError = true
          return;
        }
        if(!this.editingItem.payment_uid) {
          this.puidError = true
          return;
        }



        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'carts', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Vendita salvata"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/sells");
              break;
            case 2:
              if(this.editingItem.block_edit) {
                this.$router.replace("/sells/"+ reply.data._id + "/view");
              } else if(this.$route.params.id === 'new') {
                this.$router.replace("/sells/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  user_id : null,
                  items : [],
                  expedition : false,
                  payment_method : 'manual',
                  payment_uid : '',
                  status : 'draft',
                  address: {
                    name: "",
                    city: "",
                    province: "",
                    zip_code: ""
                  },
                  block_edit : false,
                };
              } else {
                this.$router.replace("/sells/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save user error:", err);
          let message = err.message ? err.message : "errore al salvataggio della vendita";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {

      this.refreshCollection({collection: 'cards'})
      this.refreshCollection({collection: 'users'})
      this.refreshCollection({collection: 'pauses'})
      this.loadSettings()
      this.refreshCollection({collection: 'kits'}).then(()=>{
        this.refreshCollection({collection: 'products'}).then(()=>{
          if(this.$route.params.id !== 'new') {
            this.loadData()
          }
        })
      });
    }
  }
</script>

<style scoped></style>
