<template>
  <v-text-field filled rounded :label="label" clearable v-model="modelValue" type="date" dense hide-details></v-text-field>
</template>

<script>


import utils from "../utils";

export default {
  name: "DateField",
  props : {
    label : String,
    value : String,
  },
  computed : {
    modelValue : {
      get() {
        return this.value ? utils.toDateTime(this.value).toISODate() : null
      },
      set(val) {
        if(!val) {
          this.$emit('input', null)
          return
        }
        let d = utils.toDateTime(val)
        this.$emit('input', d.toISO())
      }
    }
  }
}
</script>



<style scoped>

</style>