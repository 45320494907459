<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Nome*"
                          rounded filled
                          v-model="editingItem.name.it"
                          :error="nameError"
                          :error-messages="requiredErrorMessages(nameError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                          label="Tipologia*"
                          rounded filled
                          v-model="editingItem.type"
                         :items="sectionTypeOptions"
                          :error="typeError"
                          :error-messages="requiredErrorMessages(typeError)"
                  ></v-select>
                </v-col>

                <v-col cols="12" lg="6" v-if="editingItem.type === 'category' || editingItem.type === 'genre'">
                  <v-autocomplete
                          chips clearable
                          label="Filtro sulla tipologia"
                          rounded filled
                          :items="filterOptions"
                          v-model="editingItem.type_filter"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3" v-if="editingItem.type !== 'custom'">
                  <v-text-field
                          label="Numero massimo di elementi nella sezione"
                          rounded filled
                          persistent-hint
                          hint="0 = nessun limite"
                          v-model="editingItem.max_items"
                          type="number"
                          :error="maxItemsError"
                          :error-messages="majorErrorMessages(maxItemsError, 0)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" lg="6" v-if="editingItem.type === 'custom'">
                  <v-autocomplete
                          chips deletable-chips multiple
                          label="Elementi nella sezione"
                          rounded filled
                          :items="kitOptions"
                          v-model="editingItem.items"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Ordinamento*"
                          rounded filled
                          v-model="editingItem.order"
                          type="number"
                          :error="orderError"
                          :error-messages="majorErrorMessages(orderError, 1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  export default {
    name: "GenreForm",
    data () {
      return {
        editingItem : {
          name : { it : ''},
          type : 'custom',
          type_filter : null,
          order : 0,
          max_items : 0,
          items : [],
          last_update : new Date(),
        },
        nameError : false,
        typeError : false,
        orderError : false,
        maxItemsError : false,
        sectionTypeOptions : config.sectionTypes
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root', 'kits','genre','categories']),
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuova sezione' : 'Modifica sezione';
      },
      kitOptions() {
        return this.kits.map((item) => {
          return { text : item.title.it, value: item._id}
        })
      },
      filterOptions() {
        if(this.editingItem.type === 'genre') {
          return this.genre.map((item) => {
            return { text : item.name.it, value: item._id}
          })
        }
        if(this.editingItem.type === 'category') {
          return this.categories.map((item) => {
            return { text : item.name.it, value: item._id}
          })
        }
        return []
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection']),
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      majorErrorMessages(err, num) {
        if(err) return ["Il valore deve essere >= " + num];
        return []
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'home', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati della sezione';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.nameError = false;
        this.typeError = false;
        this.orderError = false;
        this.maxItemsError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;


        if(!this.editingItem.name.it) {
          this.nameError = true;
          return;
        }

        if(!this.editingItem.type) {
          this.typeError = true;
          return;
        }

        this.editingItem.order = utils.toInt(this.editingItem.order, 0)

        if(this.editingItem.order <= 0) {
          this.orderError = true;
          return;
        }

        this.editingItem.max_items = utils.toInt(this.editingItem.max_items, 0)
        if(this.editingItem.type !== 'custom' && this.editingItem.max_items < 0) {
          this.maxItemsError = true;
          return;
        }

        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'home', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Sezione della home salvata"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/home");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/home/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  name : { it : ''},
                  type : 'custom',
                  type_filter : null,
                  order : 0,
                  max_items : 0,
                  items : [],
                  last_update : new Date(),
                };
              } else {
                this.$router.replace("/home/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save home section error:", err);
          let message = err.message ? err.message : "errore al salvataggio della sezione";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      this.refreshCollection({collection:'kits'})
      this.refreshCollection({collection:'genre'})
      this.refreshCollection({collection:'categories'})
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
