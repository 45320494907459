<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Impostazioni del server</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save()">Salva</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <h4>Impostazioni app</h4>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Prezzo dell'Home KIt*"
                      rounded filled
                      v-model="editingItem.home_kit_price"
                      type="number"
                      step="0.01"
                      :error="priceError"
                      :error-messages="requiredErrorMessages(priceError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Prezzo della tessera MSP*"
                      rounded filled
                      v-model="editingItem.coni_card_price"
                      type="number"
                      step="0.01"
                      :error="coniPriceError"
                      :error-messages="requiredErrorMessages(coniPriceError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Prezzo di spedizione dell'Home KIt*"
                      rounded filled
                      v-model="editingItem.home_kit_expedition_price"
                      type="number"
                      step="0.01"
                      :error="priceExpError"
                      :error-messages="requiredErrorMessages(priceExpError)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Giorni per la spedizione dell'Home KIt*"
                      rounded filled
                      v-model="editingItem.home_kit_expedition_days"
                      type="number"
                      step="1"
                      :error="daysExpError"
                      :error-messages="requiredErrorMessages(daysExpError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Giorni oltre i quali un pacchetto scade dopo il primo utilizzo*"
                      rounded filled
                      v-model="editingItem.purchase_expiration_days_after_first_use"
                      type="number"
                      step="1"
                      hint="ATTENZIONE! LE MODIFICHE A QUESTO PARAMETRO NON SONO RETROATTIVE"
                      persistent-hint
                      :error="daysExpError"
                      :error-messages="requiredErrorMessages(daysExpError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h4>Impostazioni prenotazioni</h4>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° prenotazioni per settimana*"
                      rounded filled
                      v-model="editingItem.user_bookings_per_week"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° prenotazioni prenotabili dall'utente nella stessa settimana"
                      :error="maxPerWeekError"
                      :error-messages="requiredErrorMessages(maxPerWeekError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° prenotazioni per giorno*"
                      rounded filled
                      v-model="editingItem.user_bookings_per_day"
                      type="number"
                      step="1"
                      :max="editingItem.user_bookings_per_week"
                      persistent-hint
                      hint="N° prenotazioni prenotabili dall'utente nello stesso giorno"
                      :error="maxPerDayError"
                      :error-messages="requiredErrorMessages(maxPerDayError)"
                  ></v-text-field>

                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° min minuti per cancellazione*"
                      rounded filled
                      v-model="editingItem.cancel_within_minutes"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° minuti prima della seduta entro le quali è possibile cancellare una prenotazione"
                      :error="cancelError"
                      :error-messages="requiredErrorMessages(cancelError)"
                  ></v-text-field>

                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° min minuti per prenotazione (default)*"
                      rounded filled
                      v-model="editingItem.booking_within_minutes"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° minuti prima della seduta entro le quali è possibile effettuare una prenotazione (valore di default)"
                      :error="bookingMinError"
                      :error-messages="requiredErrorMessages(bookingMinError)"
                  ></v-text-field>

                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° min minuti per prenotazione in TopGymExperience"
                      rounded filled
                      v-model="editingItem.booking_within_minutes_exp"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° minuti prima della seduta TopGymExperience entro le quali è possibile effettuare una prenotazione, se il valore è a zero viene preso quello di default"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° min minuti per prenotazione in Open Gym"
                      rounded filled
                      v-model="editingItem.booking_within_minutes_macchine"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° minuti prima della seduta Open Gym entro le quali è possibile effettuare una prenotazione, se il valore è a zero viene preso quello di default"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° min minuti per prenotazione in Sala Corsi"
                      rounded filled
                      v-model="editingItem.booking_within_minutes_corsi"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° minuti prima della seduta Sala Corsi entro le quali è possibile effettuare una prenotazione, se il valore è a zero viene preso quello di default"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° min minuti per prenotazione in Vacu"
                      rounded filled
                      v-model="editingItem.booking_within_minutes_vacu"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° minuti prima della seduta Vacu entro le quali è possibile effettuare una prenotazione, se il valore è a zero viene preso quello di default"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° min minuti per prenotazione in Bike indoor"
                      rounded filled
                      v-model="editingItem.booking_within_minutes_gym"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° minuti prima della seduta Bike indoor entro le quali è possibile effettuare una prenotazione, se il valore è a zero viene preso quello di default"
                  ></v-text-field>
                </v-col>

                <v-col cols="12"><v-divider></v-divider></v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Max posti in un ora TopGym experience (default)*"
                      rounded filled
                      v-model="editingItem.bookings_per_hour_exp"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° posti max contemporanei prenotabili da più utenti in un ora TopGym experience"
                      :error="maxPerHourExpError"
                      :error-messages="requiredErrorMessages(maxPerHourExpError)"
                  ></v-text-field>

                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Max posti in un ora Sala Corsi (default)*"
                      rounded filled
                      v-model="editingItem.bookings_per_hour_corsi"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° posti max contemporanei prenotabili da più utenti in un ora in Sala Corsi"
                      :error="maxPerHourCorsiError"
                      :error-messages="requiredErrorMessages(maxPerHourCorsiError)"
                  ></v-text-field>

                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Max posti in un ora in Open Gym (default)*"
                      rounded filled
                      v-model="editingItem.bookings_per_hour_macchine"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° posti max contemporanei prenotabili da più utenti in un ora in Open Gym"
                      :error="maxPerHourMacchineError"
                      :error-messages="requiredErrorMessages(maxPerHourMacchineError)"
                  ></v-text-field>

                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Max posti in un ora in Vacu (default)*"
                      rounded filled
                      v-model="editingItem.bookings_per_hour_vacu"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° posti max contemporanei prenotabili da più utenti in un ora in Vacu"
                      :error="maxPerHourVacuError"
                      :error-messages="requiredErrorMessages(maxPerHourVacuError)"
                  ></v-text-field>

                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Max posti in un ora in Bike indoor (default)*"
                      rounded filled
                      v-model="editingItem.bookings_per_hour_gym"
                      type="number"
                      step="1"
                      persistent-hint
                      hint="N° posti max contemporanei prenotabili da più utenti in un ora in Vacu"
                      :error="maxPerHourGymError"
                      :error-messages="requiredErrorMessages(maxPerHourGymError)"
                  ></v-text-field>

                </v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° giorni max di pausa dell'abbonamento (nell'anno sportivo)"
                      rounded filled
                      v-model="editingItem.max_days_of_subscription_pause"
                      type="number"
                      step="1"
                      persistent-hint
                  ></v-text-field>

                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Costo giorno di pausa extra su abbonamento (€)"
                      rounded filled
                      v-model="editingItem.extra_day_of_sub_pause_price"
                      type="number"
                      step="1"
                      persistent-hint
                  ></v-text-field>

                </v-col>


                <v-col cols="12" md="6" lg="4" xl="3" >
                  <h4>Giorno di inizio dell'anno sportivo</h4>
                  <div class="d-flex">
                    <v-text-field
                        label="Giorno"
                        rounded filled
                        v-model="editingItem.day_of_sport_year_start"
                        type="number"
                        step="1"
                        min="1"
                        max="31"
                    ></v-text-field>

                    <v-text-field
                        label="Mese"
                        rounded filled
                        v-model="editingItem.month_of_sport_year_start"
                        type="number"
                        step="1"
                        min="1"
                        max="12"
                    ></v-text-field>
                  </div>


                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Durata slide banner (in sec)"
                      rounded filled
                      v-model="editingItem.banner_duration"
                      type="number"
                      step="1"
                      min="1"
                      persistent-hint
                  ></v-text-field>

                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save()">Salva</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import utils from '../utils';
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
export default {
  name: "GenreForm",
  data () {
    return {
      mailTo : '',
      editingItem : {
        home_kit_price : 0,
        coni_card_price : 15,
        home_kit_expedition_price : 0,
        home_kit_expedition_days : 0,
        purchase_expiration_days_after_first_use : 60,
        open_days : {
          lun: true,
          mar: true,
          mer: true,
          gio: true,
          ven: true,
          sab: true,
          dom: false,
        },
        user_bookings_per_week : 2,
        user_bookings_per_day : 1,
        bookings_per_hour_exp : 9,
        bookings_per_hour_corsi : 9,
        bookings_per_hour_macchine : 9,
        bookings_per_hour_vacu : 1,
        bookings_per_hour_gym : 1,
        cancel_within_minutes : 1,
        booking_within_minutes : 10,
        booking_within_minutes_exp : 0,
        booking_within_minutes_corsi : 0,
        booking_within_minutes_macchine : 0,
        booking_within_minutes_vacu : 0,
        booking_within_minutes_gym : 0,
        holidays : {
          natale : true,
          santo_stefano : true,
          capodanno : true,
          epifania : true,
          pasquetta : true,
          liberazione : true,
          lavoro : true,
          repubblica : true,
          ferragosto : true,
          santi : true,
          immacolata : true,
        },
        day_of_sport_year_start : 1,
        month_of_sport_year_start : 9,
        max_days_of_subscription_pause : 10,
        extra_day_of_sub_pause_price : 3,
        banner_duration : 6
      },
      priceError : false,
      coniPriceError : false,
      priceExpError : false,
      daysExpError : false,
      maxPerWeekError : false,
      maxPerDayError : false,
      maxPerHourExpError : false,
      maxPerHourCorsiError : false,
      maxPerHourMacchineError : false,
      maxPerHourVacuError : false,
      maxPerHourGymError : false,
      cancelError : false,
      bookingMinError : false,
    }
  },
  computed : {
    ...mapGetters(['token', 'profile', 'is_root']),
    loading : {
      get() { return this.$store.state.loading; },
      set(val) { this.$store.state.loading = val; }
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update','upload', 'insert']),
    requiredErrorMessages(err) {
      if(err) return ["Campo richiesto"];
      return []
    },
    loadData() {
      this.loading = true;
      this.get({collection : 'settings'}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          if(!reply.data.day_of_sport_year_start) reply.data.day_of_sport_year_start = 1;
          if(!reply.data.month_of_sport_year_start) reply.data.month_of_sport_year_start = 9;
          if(!reply.data.max_days_of_subscription_pause) reply.data.max_days_of_subscription_pause = 10;
          if(!reply.data.extra_day_of_sub_pause_price) reply.data.extra_day_of_sub_pause_price = 3;
          if(!reply.data.banner_duration) reply.data.banner_duration = 6;
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento delle impostazioni';
        this.sendError({message});
        this.$router.go(-1);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    clearErrors() {
      this.priceError = false;
      this.coniPriceError = false;
      this.priceExpError = false;
      this.daysExpError = false;
      this.maxPerWeekError = false;
      this.maxPerDayError = false;
      this.maxPerHourExpError = false;
      this.maxPerHourCorsiError = false;
      this.maxPerHourMacchineError = false;
      this.cancelError = false;
      this.bookingMinError = false;
    },
    save( ) {
      this.loading = true;
      this.clearErrors();

      this.editingItem.home_kit_price = utils.toFloat(this.editingItem.home_kit_price, 0);
      this.editingItem.coni_card_price = utils.toFloat(this.editingItem.coni_card_price, 0);
      this.editingItem.home_kit_expedition_price = utils.toFloat(this.editingItem.home_kit_expedition_price, 0);
      this.editingItem.home_kit_expedition_days = utils.toInt(this.editingItem.home_kit_expedition_days, 0);
      this.editingItem.purchase_expiration_days_after_first_use = utils.toInt(this.editingItem.purchase_expiration_days_after_first_use, 60);

      this.editingItem.user_bookings_per_week = utils.toInt(this.editingItem.user_bookings_per_week, 2);
      this.editingItem.user_bookings_per_day = utils.toInt(this.editingItem.user_bookings_per_day, 1);
      this.editingItem.bookings_per_hour_exp = utils.toInt(this.editingItem.bookings_per_hour_exp, 9);
      this.editingItem.bookings_per_hour_corsi = utils.toInt(this.editingItem.bookings_per_hour_corsi, 9);
      this.editingItem.bookings_per_hour_macchine = utils.toInt(this.editingItem.bookings_per_hour_macchine, 9);
      this.editingItem.bookings_per_hour_vacu = utils.toInt(this.editingItem.bookings_per_hour_vacu, 1);
      this.editingItem.bookings_per_hour_gym = utils.toInt(this.editingItem.bookings_per_hour_gym, 1);
      this.editingItem.cancel_within_minutes = utils.toInt(this.editingItem.cancel_within_minutes, 1);

      this.editingItem.booking_within_minutes = utils.toInt(this.editingItem.booking_within_minutes, 10);
      this.editingItem.booking_within_minutes_exp = utils.toInt(this.editingItem.booking_within_minutes_exp, 0);
      this.editingItem.booking_within_minutes_corsi = utils.toInt(this.editingItem.booking_within_minutes_corsi, 0);
      this.editingItem.booking_within_minutes_macchine = utils.toInt(this.editingItem.booking_within_minutes_macchine, 0);
      this.editingItem.booking_within_minutes_vacu = utils.toInt(this.editingItem.booking_within_minutes_vacu, 0);
      this.editingItem.booking_within_minutes_gym = utils.toInt(this.editingItem.booking_within_minutes_gym, 0);

      this.editingItem.max_days_of_subscription_pause = utils.toInt(this.editingItem.max_days_of_subscription_pause, 10);
      this.editingItem.extra_day_of_sub_pause_price = utils.toInt(this.editingItem.extra_day_of_sub_pause_price, 3);
      this.editingItem.day_of_sport_year_start = utils.toInt(this.editingItem.day_of_sport_year_start, 1);
      this.editingItem.month_of_sport_year_start = utils.toInt(this.editingItem.month_of_sport_year_start, 9);
      this.editingItem.banner_duration = utils.toInt(this.editingItem.banner_duration, 6);



      if(this.editingItem.home_kit_price <= 0) {
        this.priceError = true;
        return;
      }

      if(this.editingItem.coni_card_price <= 0) {
        this.coniPriceError = true;
        return;
      }

      if(this.editingItem.home_kit_expedition_price <= 0) {
        this.priceExpError = true;
        return;
      }
      if(this.editingItem.purchase_expiration_days_after_first_use <= 0) {
        this.daysExpError = true;
        return;
      }

      if(this.editingItem.user_bookings_per_week < 0) {
        this.maxPerWeekError = true;
        return;
      }
      if(this.editingItem.user_bookings_per_day < 0) {
        this.maxPerDayError = true;
        return;
      }
      if(this.editingItem.cancel_within_minutes < 0) {
        this.cancelError = true;
        return;
      }
      if(this.editingItem.booking_within_minutes < 0) {
        this.bookingMinError = true;
        return;
      }
      if(this.editingItem.bookings_per_hour_exp < 0) {
        this.maxPerHourExpError = true;
        return;
      }
      if(this.editingItem.bookings_per_hour_gym < 0) {
        this.maxPerHourGymError = true;
        return;
      }
      if(this.editingItem.bookings_per_hour_corsi < 0) {
        this.maxPerHourCorsiError = true;
        return;
      }
      if(this.editingItem.bookings_per_hour_vacu < 0) {
        this.maxPerHourVacuError = true;
        return;
      }
      if(this.editingItem.bookings_per_hour_macchine < 0) {
        this.maxPerHourMacchineError = true;
        return;
      }

      this.update({collection:'settings', data : this.editingItem, noId: true}).then((reply)=>{
        this.loading = false;
        if(!reply.data) {
          if(reply.message) {
            this.sendError({message: reply.message});
          } else {
            this.sendError({message: 'Oops! c\'è stato un errore'});
          }
          return
        }

        this.editingItem = reply.data;
      }).catch((err)=>{
        if(config.debug) console.log("Save settings error:", err);
        let message = err.message ? err.message : "errore al salvataggio delle impostazioni";
        this.sendError({ message });
        if(err.statusCode === 401) {
          this.$router.replace("/");
          this.logout();
        }
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped></style>
