<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Titolo*"
                          rounded filled
                          v-model="editingItem.title"
                          :error="titleError"
                          :error-messages="requiredErrorMessages(titleError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                      label="Utente"
                      rounded filled
                      v-model="editingItem.user_id"
                      :items="userOptions"
                      :error="userError"
                      :error-messages="requiredErrorMessages(userError)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                      label="Pacchetto sedute"
                      rounded filled
                      v-model="editingItem.packet_id"
                      :items="packetOptions"
                      :error="packetError"
                      :error-messages="requiredErrorMessages(packetError)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                      label="Tipo di promo*"
                      rounded filled
                      v-model="editingItem.discount_type"
                      :items="discountTypeOptions"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Valore sconto*"
                      rounded filled
                      v-model="editingItem.value"
                      :prefix="editingItem.is_percent_discount ? '%' : '€'"
                      type="number"
                      step="0.01"
                      :min="0"
                      :max="maxValue"
                      :error="valueError"
                      :error-messages="requiredErrorMessages(valueError)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                      label="Sconto in percentuale"
                      rounded filled
                      v-model="editingItem.is_percent_discount"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
            <v-btn text color="accent" @click="save(4)">Salva e aggiungi prodotti</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  export default {
    name: "PacketForm",
    data () {
      return {
        editingItem : {
          title : '',
          user_id : null,
          packet_id : null,
          discount_type : 'on_total',
          value : 0.0,
          is_percent_discount : false,
          consumed : false,
        },
        titleError : false,
        userError : false,
        packetError : false,
        valueError : false,
        discountTypeOptions : [
          { text : "Sconto sul totale", value: "on_total"},
          { text : "Sconto per seduta", value: "on_unit"},
        ]
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root','users','packets']),
      userOptions() {
        return this.users.map((item)=>{
          return { text : item.surname + ' ' + item.name, value: item._id}
        })
      },
      packetOptions() {
        return this.packets.map((item)=>{
          return { text : item.title ?  + item.title.it : item._id, value: item._id }
        })
      },
      maxValue() {
        if(this.editingItem.is_percent_discount) {
          return 100;
        } else if(this.editingItem.packet_id !== null) {
          let packet = this.packets.filter((item) => item._id === this.editingItem.packet_id)[0];
          if(this.editingItem.discount_type === 'on_total') {
            return packet.price
          } else {
            return (packet.price / packet.num_seats).toFixed(2)
          }
        }
        return null
      },
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuova promozione' : 'Modifica promozione';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      },
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection','upload']),
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'promo', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            if(reply.data.consumed) {
              this.$router.go(-1);
              return;
            }
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati della promozione';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.titleError = false;
        this.userError = false;
        this.packetError = false;
        this.valueError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;
        if(!this.editingItem.title) {
          this.titleError = true;
          return;
        }
        if(!this.editingItem.user_id) {
          this.userError = true;
          return;
        }
        if(!this.editingItem.packet_id) {
          this.packetError = true;
          return;
        }

        this.editingItem.value = utils.toFloat(this.editingItem.value, 0);
        let maxValue = utils.toFloat(this.maxValue, 0);
        if(!this.editingItem.value <= 0 || this.editingItem.value > maxValue) {
          this.valueError = true;
          return;
        }



        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'promo', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Promozione salvata"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/promo");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/promo/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  title : '',
                  user_id : null,
                  packet_id : null,
                  discount_type : 'on_total',
                  value : 0.0,
                  is_percent_discount : false,
                  consumed : false,
                };
              } else {
                this.$router.replace("/promo/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save promo error:", err);
          let message = err.message ? err.message : "errore al salvataggio della promozione";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      this.refreshCollection({collection:'users'});
      this.refreshCollection({collection:'packets'});
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
