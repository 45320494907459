<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Gallery</h2>
            <v-spacer />
            <v-btn color="accent" text @click="$router.push('/gallery/new')">Nuova</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                              no-data-text="Non ci sono generi censiti al momento."  :footer-props="footerProps">

                  <template v-slot:item.icon="{ item }">
                    <v-img :src="item.icon" :aspect-ratio="1" class="flex-shrink-0" min-width="150"></v-img>
                  </template>
                  <template v-slot:item.title="{ item }">
                    <a :href="'./#/gallery/'+item._id" ><b>{{ item.title }}</b></a>
                  </template>
                  <template v-slot:item.pub="{ item }">
                    <strong v-if="item.pub" class="success--text">Sì</strong>
                    <strong v-else class="error--text">No</strong>
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'gallery form', params : { id : item._id }})">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.remove="{ item }">
                    <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                      <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from '../utils'
export default {
  name: "BannerList",
  data () {
    return {
      footerProps : {
        "items-per-page-text" : "N° risultati per pagina",
        "items-per-page-options" : [10,20,50,100],
        "items-per-page" : 10
      },
      nameSearch : null,
      emailSearch : null,
      roleSearch : null,
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'Immagine', sortable: false, align: 'left', value : 'icon', width: 150 },
        { text : 'Nome', sortable: true, align: 'left', value : 'title' },
        { text : 'Priorità', sortable: true, align: 'left', value : 'priority' },
        { text : 'Pubblicato', sortable: true, align: 'left', value : 'pub' },
        { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
        { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
      ],
      roleOptions : config.roles,
      pagination: {},
      query : {}
    }
  },
  watch: {
    pagination: {
      handler () {
        this.refresh();
      },
      deep: true
    }
  },
  computed : {
    ...mapGetters(['profile'])
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete']),
    formatDate(date) {
      if(!date) return '-'
      return utils.formatDate(date)
    },
    applyFilter() {
      this.query = {};
      this.refresh();
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questa immagine?', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'gallery', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Immagine eliminata!'});
            }).catch((err)=>{
              let message = err.message ? err.message : "errore alla cancellazione dell'immagine";
              this.sendError({ message });
              if(config.debug) console.error("Errore di cancellazione dell'immagine",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            });
          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) order = 'DESC';
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page, sort, order
      };

      this.load({ collection : 'gallery', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento della gallery."});
        if(config.debug) console.error("Errore al caricamento della gallery: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>