<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="ma-4">
            <h2 class="display-1">VISITE MEDICHE</h2><v-spacer /><h2 class="display-1 text-uppercase">{{clockDate}} {{clockTime}}</h2>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row class="ma-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" lg="4" xl="3">
                      <v-menu v-model="menuOpen" :close-on-content-click="false" >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text rounded outlined color="accent" v-on="on" v-bind="attrs">{{formatDate(calendarDate)}}</v-btn>
                        </template>
                        <v-card tile>
                          <v-card-title>Imposta la data</v-card-title>
                          <v-card-text>
                            <v-text-field rounded filled label="Data" v-model="menuDate" type="date" class="flex-grow-0" style="min-width: 200px" ></v-text-field>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text color="accent" @click="applyMenuDate()">Applica</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>

                    </v-col>
                    <v-col cols="12" lg="8" xl="9" class="d-flex justify-center justify-lg-end">
                      <v-btn text rounded outlined color="accent" @click="setToday()">Oggi</v-btn>
                      <v-btn text rounded outlined color="accent" @click="cal.prev()" class="ml-1">Precedente</v-btn>
                      <v-btn text rounded outlined color="accent" @click="cal.next()" class="ml-1">Successivo</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-calendar
                      ref="calendar"
                      v-model="calendarDate"
                      type="day"
                      locale="it"
                      :weekdays="[1,2,3,4,5,6,0]"
                      first-time="14:00"

                      :interval-count="36"
                      :interval-minutes="10"
                      :short-intervals="false"
                      :events="events"
                      @click:event="showEventPopup"
                  >
                    <template #day-body="{ date, week }">
                      <div
                          class="v-current-time"
                          :class="{ first: date === week[0].date }"
                          :style="{ top: nowY }"
                      ></div>
                    </template>

                    <template #day-label-header="{ date }">
                      <div >
                        <h2>{{formatDate(date)}}</h2>
                        <v-chip class="ml-1 mr-1" dark x-small color="cyan" v-if="isToday(date)">OGGI</v-chip>
                        <v-chip class="ml-1 mr-1" dark x-small color="error" v-if="isHoliday(date)">CHIUSURA</v-chip>
                      </div>

                    </template>
                  </v-calendar>


                </v-col>

              </v-row>
              <v-divider v-if="is_editor" class="ml-4 mr-4" />
              <v-row v-if="is_editor" class="ma-2">
                <v-col cols="12">
                  <h3>Inserisci prenotazione</h3>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete outlined rounded label="Utente" :items="userOptions" v-model="newItem.user_id"
                                  :error="userError" :error-messages="requiredErrorMessage(userError)">

                    <template v-slot:selection="data">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.fiscal_code"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.fiscal_code"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field outlined rounded label="Data" type="date" v-model="bookingDate" clearable
                                :error="dateError" :error-messages="requiredErrorMessage(dateError)"/>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field type="time" outlined rounded label="Quando" v-model="bookingTime"
                                :error="dateError" :error-messages="requiredErrorMessage(dateError)" />
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" class="d-flex flex-column">
                  <p class="mb-0">Stai inserendo <strong>{{formatDateTime(newItem.when)}}</strong></p>
                  <v-btn color="accent" rounded @click="insertBooking()" :disabled="!newItem.user_id || !newItem.when">Inserisci</v-btn>
                </v-col>
              </v-row>
              <v-divider v-if="is_admin" class="mb-3" />

              <v-row v-if="is_admin">
                <v-col cols="12"><h3>Date disponibili</h3></v-col>
                <v-col cols="12" lg="6">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Data</th>
                        <th>Dalle</th>
                        <th>Alle</th>
                        <th style="width: 60px" class="text-center">Azioni</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(h, i) in hours" :key="h._id" >

                        <td>{{formatDate(h.from)}}</td>
                        <td>{{formatTime(h.from)}}</td>
                        <td>{{formatTime(h.to)}}</td>
                        <td style="width: 60px" class="text-center">
                          <v-btn text icon color="error" @click="removeHour(h._id)"><v-icon>mdi-close</v-icon></v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12"><h4>Aggiungi una data per le visite mediche</h4></v-col>
                    <v-col cols="12" lg="6" xl="3">
                      <v-text-field type="date" outlined rounded label="Data" v-model="availDate" />
                    </v-col>
                    <v-col cols="12" lg="3" xl="2">
                      <v-text-field type="time" outlined rounded label="Dalle" v-model="availFrom" />
                    </v-col>
                    <v-col cols="12" lg="3" xl="2">
                      <v-text-field type="time" outlined rounded label="Alle" v-model="availTo" />
                    </v-col>
                    <v-col cols="12" lg="3" xl="2">
                      <v-btn color="accent" rounded @click="addHour()">Inserisci</v-btn>
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-dialog v-model="eventDialog" max-width="400">
      <v-card>
        <v-card-title>Dettaglio prenotazione</v-card-title>
        <v-card-text v-if="selectedEvent">
          <h2>{{selectedEvent.name}}</h2>
          <p>{{formatDateExt(selectedEvent.start)}} dalle {{formatTime(selectedEvent.start)}} alle {{formatTime(selectedEvent.end)}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="is_editor" color="error" @click="removeSelectedBooking()" text>Rimuovi</v-btn>
          <v-spacer></v-spacer>
          <v-btn  @click="eventDialog = false;" text>Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import utils from '../utils';
import { mapGetters, mapActions } from 'vuex';
import {DateTime} from "luxon";

export default {
  name: "OpenGym",
  data () {
    return {
      clock : null,
      clockDate : '',
      clockTime : '',

      eventDialog : false,
      menuOpen : false,
      menuDate : DateTime.now().toISODate(),
      calendarDate : DateTime.now().toISODate(),
      calendarReady: false,
      bookings : [],
      holidays : {},
      selectedEvent : null,
      newItem : {
        user_id : null,
        //day : DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday,
        when: DateTime.now().set({hours : Math.min(DateTime.now().hour+1, 23), minutes : 0}).toISO(),
      },
      dateError : false,
      timeError : false,
      userError : false,
      hourError : false,
      mailTo : '',
      settings : {
        home_kit_price : 0,
        home_kit_expedition_price : 0,
        home_kit_expedition_days : 0,
        purchase_expiration_days_after_first_use : 60,
        open_days : {
          lun: true,
          mar: true,
          mer: true,
          gio: true,
          ven: true,
          sab: true,
          dom: false,
        },
        free_seat_every_x_seats : 30,
        restricted_users_starts_from_num : 200,
        user_bookings_per_week : 2,
        user_bookings_per_day : 1,
        seats_days_of_validity : 365,
        bookings_per_hour : 9,
        one_to_one_per_hour : 1,
        cancel_within_minutes : 1,
        booking_within_minutes : 10,
        holidays : {
          natale : true,
          santo_stefano : true,
          capodanno : true,
          epifania : true,
          pasquetta : true,
          liberazione : true,
          lavoro : true,
          repubblica : true,
          ferragosto : true,
          santi : true,
          immacolata : true,
        }
      },

      hours : [],
      newHour : {
        from : DateTime.now().set({hour: 14, minute : 0}).toISO(),
        to : DateTime.now().set({hour: 20, minute : 0}).toISO(),
      },
      newHourRange : {
        day : 1,
        from: "14:00",
        to: "20:00"
      },
      days : [
        { text : 'Lunedì', value : 1},
        { text : 'Martedì', value : 2},
        { text : 'Mercoledì', value : 3},
        { text : 'Giovedì', value : 4},
        { text : 'Venerdì', value : 5},
        { text : 'Sabato', value : 6},
        { text : 'Domenica', value : 0},
      ]
    }

  },
  watch: {
    calendarDate() {
      this.menuDate = this.calendarDate;
      this.loadBookingData()
    }
  },
  computed : {
    ...mapGetters(['token', 'profile', 'is_root','users', 'is_editor', 'is_admin']),
    loading : {
      get() { return this.$store.state.loading; },
      set(val) { this.$store.state.loading = val; }
    },
    // booking
    bookingDate : {
      get() { return DateTime.fromISO(this.newItem.when).toISODate() },
      set(val) {
        if(!val) {
          return;
        }

        let composed = val + " " + DateTime.fromISO(this.newItem.when).toFormat("HH:mm")
        const parsed = DateTime.fromFormat(composed, "yyyy-MM-dd HH:mm")
        this.newItem.when = parsed.toISO()
      }
    },
    // booking
    bookingTime : {
      get() { return DateTime.fromISO(this.newItem.when).toFormat("HH:mm") },
      set(val) {
        if(!val) {
          return;
        }

        let composed = DateTime.fromISO(this.newItem.when).toISODate() + " " + val
        const parsed = DateTime.fromFormat(composed, "yyyy-MM-dd HH:mm")
        this.newItem.when = parsed.toISO()
      }
    },

    availDate : {
      get() { return DateTime.fromISO(this.newHour.from).toISODate() },
      set(val) {
        if(!val) {
          return;
        }

        let composed = val + " " + DateTime.fromISO(this.newHour.from).toFormat("HH:mm")
        this.newHour.from = DateTime.fromFormat(composed, "yyyy-MM-dd HH:mm").toISO()
        composed = val + " " + DateTime.fromISO(this.newHour.to).toFormat("HH:mm")
        this.newHour.to = DateTime.fromFormat(composed, "yyyy-MM-dd HH:mm").toISO()
      }
    },
    availFrom : {
      get() { return DateTime.fromISO(this.newHour.from).toFormat("HH:mm") },
      set(val) {
        if(!val) {
          return;
        }

        let composed = DateTime.fromISO(this.newHour.from).toISODate() + " " + val
        const parsed = DateTime.fromFormat(composed, "yyyy-MM-dd HH:mm")
        this.newHour.from = parsed.toISO()
      }
    },
    availTo : {
      get() { return DateTime.fromISO(this.newHour.to).toFormat("HH:mm") },
      set(val) {
        if(!val) {
          return;
        }

        let composed = DateTime.fromISO(this.newHour.from).toISODate() + " " + val
        const parsed = DateTime.fromFormat(composed, "yyyy-MM-dd HH:mm")
        this.newHour.to = parsed.toISO()
      }
    },


    cal () {
      return this.calendarReady ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    userOptions() {
      return this.users.map((item)=>{
        return {text:item.surname + ' ' + item.name, fiscal_code : item.fiscal_code || "", value : item._id};
      })
    },
    bookingHoursOptions() {
      return this.hours.filter((item)=>{
        if(!this.newItem.date) return false
        console.log("this.newItem.date.getDay", this.newItem.date.getDay())
        return item.day === this.newItem.date.getDay();
      }).map((item) => {
        return { text: item.time, value : item._id }
      })
    },
    events() {
      return this.bookings.map(item => {
        const date = DateTime.fromISO(item.when)
        return {
          _id : item._id,
          item : item,
          name : `${item.user.name} ${item.user.surname}`,
          start : date.toJSDate(),
          end : date.plus({ minute : 10}).toJSDate(),
          color : 'info',
          timed : true
        }
      })
    },
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection','upload']),
    applyMenuDate() {
      this.calendarDate = this.menuDate;
      this.menuOpen = false;
    },
    updateClock(){
      let now = DateTime.now();
      this.clockDate = now.toFormat('DDD')
      this.clockTime = now.toFormat('HH:mm:ss')
      if(this.cal) this.cal.updateTimes()
    },
    showEventPopup( e ) {
      console.log(e)
      this.selectedEvent = e.event
      this.eventDialog = true;
    },
    updateBookingDate() {
      let temp = this.bookingDate
      this.bookingDate = temp;
    },
    requiredErrorMessage(err) {
      return err ? ['Campo richiesto'] : [];
    },
    formatDateExt(date) {
      return utils.toDateTime(date).toFormat('DDD')
    },
    formatTime(date) {
      return utils.toDateTime(date).toFormat('HH:mm')
    },
    isToday( date ) {
      return utils.toDateTime(date).startOf('day') === DateTime.now().startOf('day')
    },
    isHoliday( date ) {
      if(this.holidays[date] !== undefined) {
        return this.holidays[date];
      }
      this.checkIfHoliday( date )
      return false
    },
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    checkIfHoliday( date ) {
      this.get({collection:'holidays', id:'check/' + date }).then((reply)=>{
        this.holidays[date] = reply.data
      }).catch((err) => { console.log(err)})
    },
    async removeSelectedBooking() {
      this.loading = true;
      try {
        await this.delete({collection:'medicalbooking', id: this.selectedEvent._id})
        this.sendSuccess({message:"Prenotazione eliminata"})
      } catch (e) {
        let message = e && e.message ? e.message : "Oops! c'è stato un errore"
        this.sendError({message})
      }
      this.loading = false;
      this.eventDialog = false;
      this.loadBookingData()
    },
    setToday() {
      const now = this.cal.getNow()
      this.calendarDate = now.date;
    },
    loadBookingData() {

      this.loading = true;
      this.load({collection:'medicalbooking', filter : utils.fullFilter({ from : this.calendarDate, to : this.calendarDate }, 0,1, 'date', 'ASC')}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.bookings = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento delle prenotazioni';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    insertBooking() {
      this.dateError = !this.newItem.when;
      this.userError = !this.newItem.user_id;
      if(this.dateError || this.userError) {
        return;
      }
      this.loading = true;
      this.insert({collection:'medicalbooking', data:this.newItem}).then((reply)=>{
        this.loading = false;
        this.loadBookingData();
      }).catch((err)=>{
        this.loading = false;
        let message = err.message ? err.message : "errore all'inserimento della prenotazione";
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },


    // calendar
    allowedMinutes: v => v === 0,
    hourStyleClass(hour){
      let classes = ["hour-cell"]
      if(hour.is_one_to_one) {
        classes.push('one-to-one')
      }
      if(hour.is_one_to_one) {
        classes.push('restricted')
      }
      return classes.join(' ')
    },
    requiredErrorMessages(err) {
      if(err) return ["Campo richiesto"];
      return []
    },
    formatDate(date) {
      return utils.formatDate(date)
    },
    formatDateTime(date) {
      return utils.formatDateTime(date)
    },
    refreshHours() {
      this.loading = true;
      this.load({collection:'medicalhours', filter : utils.fullFilter({}, 0,1, 'time', 'ASC')}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.hours = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento degli orari';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    addHour() {
      if(!this.newHour.from || !this.newHour.to) {
        this.sendError({message:"Inserisci delle date valide"});
        return;
      }

      this.requestConfirm({title:"Richiesta conferma", message:"Vuoi davvero inserire questa data?", callback: (confirmed) => {
          if(confirmed) {
            this.loading = true;
            this.insert({collection:'medicalhours', data:this.newHour}).then((reply)=>{
              this.loading = false;
              if(reply.data) {
                this.refreshHours()
              }
            }).catch((err) => {
              this.loading = false;
              let message = err.message ? err.message : 'errore al salvataggio delle ore';
              this.sendError({message});
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });

    },
    getUserFullName( id ) {
      let filter = this.users.filter(item => item._id === id);
      if(!filter.length) return '-';
      return filter[0].name + " " + filter[0].surname;
    },
    removeHour(id) {
      this.requestConfirm({title:"Richiesta conferma", message:"Vuoi davvero eliminare questa ora dal calendario settimanale?", callback: (confirmed) => {
          if(confirmed) {
            this.loading = true;
            this.delete({collection:'medicalhours', id}).then((reply)=>{
              this.loading = false;
              this.refreshHours()
            }).catch((err) => {
              this.loading = false;
              let message = err.message ? err.message : "errore alla cancellazione dell'orario";
              this.sendError({message});
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });
    },
    loadSettings() {
      this.loading = true;
      this.get({collection : 'settings'}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.settings = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento delle impostazioni del calendario';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
  },
  mounted() {
    this.refreshCollection({collection:'users'});
    this.clock = setInterval(this.updateClock, 1000);
    this.calendarReady = true;
    this.scrollToTime()
    this.refreshHours()
    this.loadSettings()
    this.loadBookingData();
  },


  beforeDestroy() {
    clearInterval(this.clock);
  }
}
</script>
<style scoped>

.header-cal {
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}
.v-current-time .first::before {
  content: '';
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}

</style>