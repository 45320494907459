<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row class="flex-wrap">
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Email*"
                      rounded filled
                      v-model="editingItem.email"
                      type="email"
                      :error="emailError"
                      :error-messages="emailInvalidMessages"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Nome*"
                      rounded filled
                      v-model="editingItem.name"
                      :error="nameError"
                      :error-messages="requiredErrorMessages(nameError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Cognome*"
                      rounded filled
                      v-model="editingItem.surname"
                      :error="surnameError"
                      :error-messages="requiredErrorMessages(surnameError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="3" xl="3">
                  <v-select
                      label="Ruolo utente*"
                      :items="roles"
                      rounded filled
                      v-model="editingItem.role"
                      :error="roleError"
                      :error-messages="requiredErrorMessages(roleError)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Indirizzo"
                      rounded filled
                      v-model="editingItem.address.name"
                      hint="Via\Piazza ..."
                  ></v-text-field>
                </v-col>
                <v-col cols="8" md="4" lg="3" xl="2">
                  <v-text-field
                      label="Città"
                      rounded filled
                      v-model="editingItem.address.city"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="2" lg="2" xl="1">
                  <v-text-field
                      label="Provincia"
                      rounded filled
                      v-model="editingItem.address.province"
                      hint="es. PG"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="CAP"
                      rounded filled
                      v-model="zipCode"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Codice fiscale"
                      rounded filled
                      v-model="editingItem.fiscal_code"
                      :rules="[rules.fiscalCode]"
                      @input="fiscalCodeUppercaser"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                      label="Tipologia utente"
                      rounded filled
                      v-model="editingItem.user_type"
                      :items="userTypeOptions"
                      disabled
                  ></v-select>
                </v-col>

                <v-col cols="8" md="4" lg="3" xl="2">
                  <v-text-field
                      label="Luogo di nascita"
                      rounded filled
                      v-model="editingItem.city_of_birth"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="2" lg="2" xl="1">
                  <v-text-field
                      label="Provincia di nascita"
                      rounded filled
                      v-model="editingItem.province_of_birth"
                      hint="es. PG"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Telefono"
                      rounded filled
                      v-model="editingItem.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Data di nascita"
                      type="date"
                      rounded filled
                      v-model="dateOfBirth"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Altezza (cm)*"
                      rounded filled
                      type="number"
                      :step="1"
                      v-model="editingItem.height"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Peso (kg)*"
                      rounded filled
                      type="number"
                      :step="1"
                      v-model="editingItem.weight"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                      label="Qta di allenamento medio*"
                      rounded filled
                      persistent-hint
                      hint="a settimana"
                      :items="trainingOptions"
                      v-model="editingItem.training"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      :label="'Password' + (updating ? '' : '*')"
                      type="password"
                      rounded filled
                      v-model="editingItem.password"
                      counter
                      hint="min 6 caratteri"
                      :error="noPasswordError || malformedPasswordError"
                      :error-messages="requiredValidPasswordMessages"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      :label="'Conferma password' + (updating ? '' : '*')"
                      type="password"
                      rounded filled
                      v-model="editingItem.confirm_password"
                      hint="uguale alla password"
                      :error="confirmPasswordError"
                      :error-messages="confirmPasswordErrorMessages"
                      counter
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                      label="Sandbox user"
                      rounded filled
                      v-model="editingItem.sandbox"
                      persistent-hint
                      hint="effettua i pagamenti su paypal sandbox (ambiente di test)"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="editingItem.user_type === 'card'">
                  <v-switch
                      label="Accettazione responsabilità certificato medico"
                      rounded filled
                      v-model="editingItem.certificate"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                      label="Privacy policy - marketing"
                      disabled
                      rounded filled
                      v-model="editingItem.privacy_1"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                      label="Privacy policy - marketing partners"
                      disabled
                      rounded filled
                      v-model="editingItem.privacy_2"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                      label="Privacy policy - profilazione"
                      disabled
                      rounded filled
                      v-model="editingItem.privacy_3"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                      label="Possiede Home Kit"
                      rounded filled
                      v-model="editingItem.home_kit"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                      label="Attivo"
                      rounded filled
                      v-model="editingItem.active"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                      label="Abbonamenti in pausa"
                      rounded filled
                      v-model="editingItem.pause_subscription"
                      :hint="`Giorni di pausa utilizzati: ${editingItem.num_pauses} di ${settings.max_days_of_subscription_pause}`"
                      persistent-hint
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-textarea filled rounded label="Note" v-model="editingItem.notes"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea filled rounded label="Note per l'utente" v-model="editingItem.user_notes" hint="ATTENZIONE! Queste note saranno visibili dall'utente nel frontend" persistent-hint></v-textarea>
                </v-col>
              </v-row>
              <v-divider v-if="editingItem.user_type === 'card'"/>
              <v-row v-if="editingItem.user_type === 'card'">
                <v-col cols="12">
                  <h4 class="title">Informazioni tessera CONI</h4>
                </v-col>
                <v-col cols="12" v-if="card">
                  <ul>
                    <li><span class="font-weight-bold">Numero tessera:</span> {{card.number}}</li>
                    <li><span class="font-weight-bold">Assicurazione:</span> {{card.insurance}}</li>
                    <li :class="cardExpirationClass(card.expiration)"><span class="font-weight-bold">Scadenza:</span> {{formatDate(card.expiration)}}</li>
                  </ul>
                </v-col>
                <v-col v-else class="font-italic">
                  Nessuna tessera presente in database.
                </v-col>

              </v-row>
              <v-divider v-if="editingItem.user_type === 'card'" class="mt-4"/>
              <v-row v-if="editingItem.user_type === 'card'">
                <v-col cols="12">
                  <h4 class="title">Documenti</h4>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Scadenza del green pass" type="date" clearable filled rounded v-model="greenPassExp"/>
                </v-col>
                <v-col cols="12" lg="6">
                  <h5>Documento d'identità</h5>
                  <div class="d-flex">
                    <v-btn v-if="editingItem.identity_card_front" rounded color="accent" @click="openPrivateLink('identityfront')">Vedi fronte</v-btn>
                    <v-btn class="ml-3" v-if="editingItem.identity_card_rear" rounded color="accent" @click="openPrivateLink('identityrear')">Vedi retro</v-btn>
                  </div>

                  <span class="font-italic" v-if="!editingItem.identity_card_front && !editingItem.identity_card_rear">Nessun documento d'identità presente in database.</span>
                  <v-file-input rounded filled label="Allega fronte" v-model="identityCardFront" class="mt-3"/>
                  <v-file-input rounded filled label="Allega retro" v-model="identityCardRear" class="mt-3"/>
                </v-col>
                <v-col cols="12" lg="6">
                  <h5>Certificato medico</h5>
                  <v-btn v-if="editingItem.medical_cert" rounded color="accent" @click="openPrivateLink('medical')">Vedi certificato</v-btn>
                  <span v-else class="font-italic">Nessun certificato medico presente in database.</span>
                  <v-file-input rounded filled label="Allega certificato" v-model="medicalCert" class="mt-3"/>
                  <v-text-field label="Scadenza certificato" type="date" clearable filled rounded v-model="medicalCertExp"/>
                </v-col>

              </v-row>
              <v-divider v-if="editingItem.user_type === 'card'" class="mt-4"/>
              <v-row v-if="editingItem.user_type === 'card'">
                <v-col cols="12">
                  <h4 class="title">Scheda di allenamento</h4>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-btn v-if="editingItem.scheda" rounded color="accent" @click="openPrivateLink('scheda')">Vedi scheda</v-btn>
                  <span v-else class="font-italic">Nessuna scheda di allenamento presente in database.</span>
                  <v-file-input rounded filled label="Allega scheda" v-model="scheda" class="mt-3" />
                </v-col>
              </v-row>
              <v-divider class="my-3" v-if="editingItem._id"></v-divider>
              <v-row v-if="editingItem._id">
                <v-col cols="12" class="d-flex align-center">
                  <h4 class="title">Extra pause abbonamenti</h4>
                  <v-btn class="ml-3" rounded color="accent" @click="addPauseDialog = true">Aggiungi <v-icon>mdi-plus</v-icon></v-btn>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="6" md="4" lg="2">
                      <v-switch hide-details v-model="query.valid" label="Nascondi pause scadute" @change="applyFilter" ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-data-table  :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                 no-data-text="L'utente non ha pause abbonamento extra."  :footer-props="footerProps">
                    <template v-slot:item.from="{ item }">
                      {{ formatDate(item.from) }}
                    </template>
                    <template v-slot:item.to="{ item }">
                      {{ formatDate(item.to) }}
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn color="accent" dark text icon v-on:click="editPause(item)">
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark text icon v-on:click="requestPauseDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-dialog v-model="addPauseDialog" max-width="800" :persistent="tableLoading">
                <v-card>
                  <v-form ref="pauseForm" @submit.prevent="savePause">
                    <v-card-title class="accent--text">{{pauseItem._id ? 'Modifica':'Aggiungi una'}} pausa abbonamento</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="16" lg="6">
                          <v-text-field type="date" v-model="pauseItem.from" label="Valida dal" :rules="[rules.required]" rounded filled></v-text-field>
                        </v-col>
                        <v-col cols="16" lg="6">
                          <v-text-field type="date" v-model="pauseItem.to" label="Valida fino al (non compreso)" :rules="[rules.required]" rounded filled></v-text-field>
                        </v-col>
                        <v-col cols="16" lg="6">
                          <v-text-field type="number" v-model="pauseItem.num_days" label="Numero di giorni" :rules="[rules.required, rules.minInt(1)]" rounded filled></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="addPauseDialog = false" :disabled="tableLoading">Annulla</v-btn>
                      <v-btn text color="accent" type="submit" :disabled="tableLoading" :loading="tableLoading">Salva</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import utils from "../utils";
import { mapGetters, mapActions } from "vuex";
import config from "../config";
import rules from "../utils/rules";
import {DateTime} from "luxon";
export default {
  name: "UserForm",
  data() {
    return {
      addPauseDialog : false,
      pauseItem : {
        from : null,
        to : null,
        num_days : 7
      },
      card : null,
      scheda : null,
      identityCardFront : null,
      identityCardRear : null,
      medicalCert : null,
      medicalCertExp : null,
      greenPassExp : null,
      editingItem: {
        email: "",
        name: "",
        surname: "",
        role: "user",
        phone: "",
        height: 0,
        weight: 0,
        training: 0,
        fiscal_code: "",
        address: {
          name: "",
          city: "",
          province: "",
          zip_code: ""
        },
        city_of_birth: '',
        province_of_birth: '',
        date_of_birth: new Date(),
        register_date: new Date(),
        password: "",
        user_type: "commercial",
        confirm_password: "",
        sandbox: false,
        active: true,
        certificate: false,
        privacy_1 : true,
        privacy_2 : true,
        privacy_3 : true,
        home_kit : false,
        identity_card_front : '',
        identity_card_rear : '',
        medical_cert : '',
        medical_cert_expiration : null,
        scheda : '',
        green_pass_expiration : null,
        notes : '',
        user_notes : '',
        pause_subscription : false,
        num_pauses: 0
      },
      trainingOptions : [
        { text : "Meno di 1 giorno", value : 0},
        { text : "1 giorno", value : 1},
        { text : "2 giorni", value : 2},
        { text : "3 giorni", value : 3},
        { text : "4 giorni", value : 4},
        { text : "5 giorni", value : 5},
        { text : "6 giorni", value : 6},
        { text : "7 giorni", value : 7},
      ],


      noPasswordError: false,
      malformedPasswordError: false,
      confirmPasswordError: false,
      emailError: false,
      roleError: false,
      nameError: false,
      surnameError: false,
      addressError: false,
      roles: config.roles,
      userTypeOptions: [
        { text: "Commerciale", value: "commercial" },
        { text: "Tesserato MSP", value: "card" }
      ],
      settings : {
        max_days_of_subscription_pause : 10,
        month_of_sport_year_start : 9,
        day_of_sport_year_start : 1,
      },
      rules: {
        fiscalCode: value => {
          if(utils.isValidCF(value)) {
            this.loadCard();
            return true;
          }
          this.card = null;
          return "Codice fiscale non valido";
        },
        ...rules
      },


      footerProps : {
        "items-per-page-text" : "N° risultati per pagina",
        "items-per-page-options" : [10,20,50,100],
        "items-per-page" : 10
      },
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'Valido dal', sortable: true, align: 'left', value : 'from' },
        { text : 'Valido fino al (non compreso)', sortable: true, align: 'left', value : 'to' },
        { text : 'N° giorni', sortable: true, align: 'left', value : 'num_days' },
        { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
        { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
      ],
      pagination: {},
      query : {
        valid : true
      }
    };
  },
  computed: {
    ...mapGetters(["token", "profile", "is_root"]),
    dateOfBirth: {
      get() {
        if (this.editingItem.date_of_birth && this.editingItem.date_of_birth instanceof Date && !isNaN(this.editingItem.date_of_birth)) { // isNaN wont accept a date in typescript, use date.getTime() instead to produce a number
          return DateTime.fromJSDate(this.editingItem.date_of_birth).toISODate();
        }
        return DateTime.fromISO(this.editingItem.date_of_birth).toISODate();
      },
      set(newVal) {
        this.editingItem.date_of_birth = DateTime.fromISO(newVal).toJSDate();
      }
    },
    zipCode : {
      get() {
        return this.editingItem.address.zip_code
      },
      set( value ) {
        this.editingItem.address.zip_code = value;

        let cap = parseInt(value, 10);

        if (Number.isNaN(cap)) {
          this.editingItem.user_type = 'commercial';
          console.log("commercial");
          return
        }

        if(cap >= 5010 && cap <= 5100) {
          //terni
          this.editingItem.user_type = 'card';
          console.log("card");
          return
        }
        if(cap >= 6010 && cap <= 6089) {
          //perugia
          this.editingItem.user_type = 'card';
          console.log("card");
          return
        }
        if(cap >= 6121 && cap <= 6135) {
          //perugia 2
          this.editingItem.user_type = 'card';
          console.log("card");
          return
        }
        console.log("comm");
        this.editingItem.user_type = 'commercial';
      }
    },


    updating() {
      return !!this.editingItem._id;
    },
    title() {
      return this.$route.params.id === "new"
          ? "Nuovo utente"
          : "Modifica utente";
    },
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.state.loading = val;
      }
    },
    emailInvalidMessages() {
      if (this.emailError) return ["Inserisci una mail valida"];
      return [];
    },
    requiredValidPasswordMessages() {
      if (this.confirmPasswordError)
        return ["E' richiesta una password di almeno 6 caratteri"];
      return [];
    },
    confirmPasswordErrorMessages() {
      if (this.confirmPasswordError)
        return ["Password e conferma password non corrispondono"];
      return [];
    }
  },
  methods: {
    ...mapActions([
      "sendSuccess",
      "sendError",
      "requestConfirm",
      "logout",
      "load",
      "delete",
      "get",
      "update",
      "upload",
      "insert"
    ]),
    resetPauseItem() {
      this.pauseItem = {
        from : null,
        to : null,
        num_days : 7
      }
      const dt =  DateTime.now().set({ month : this.settings.month_of_sport_year_start, day : this.settings.day_of_sport_year_start }).startOf('day')
      if(DateTime.now() < dt) {
        this.pauseItem.from = dt.minus({year : 1}).toISODate()
        this.pauseItem.to = dt.toISODate()
      } else {
        this.pauseItem.from = dt.toISODate()
        this.pauseItem.to = dt.plus({year : 1}).toISODate()
      }
    },
    async savePause() {
      console.log("savePause")
      if(!this.$refs.pauseForm.validate()) return;
      console.log("savePause ok")
      this.tableLoading = true;
      this.pauseItem.user_id = this.editingItem._id;
      this.pauseItem.from = DateTime.fromISO(this.pauseItem.from).startOf("day").toJSON()
      this.pauseItem.to = DateTime.fromISO(this.pauseItem.to).startOf("day").toJSON()
      this.pauseItem.num_days = utils.toInt(this.pauseItem.num_days, 1);
      let saveFn = this.insert;
      if(this.pauseItem._id) {
        saveFn = this.update
      }
      try {
        await saveFn({collection:'pauses', data : this.pauseItem})
        await this.applyFilter()
        this.resetPauseItem();
        this.addPauseDialog = false
      } catch (err) {
        this.tableLoading = false;
        this.sendError({message:"Errore al salvataggio della pausa abbonamento."});
        if(config.debug) console.error("Errore al salvataggio della pausa abbonamento: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      }
    },
    editPause( item ) {
      this.pauseItem = {
        _id : item._id,
        from : DateTime.fromISO(item.from).toISODate(),
        to : DateTime.fromISO(item.to).toISODate(),
        user_id : item.user_id,
        num_days : item.num_days,
      }
      this.addPauseDialog = true
    },
    requestPauseDelete(id) {
      this.requestConfirm({title:"Richiesta conferma", message:"Sei sicuro di voler eliminare questa pausa extra di abbonamento?", callback : async ok =>
        {
          if(!ok) return
          try {
            await this.delete({collection:'pauses', id})
            this.resetPauseItem()
            await this.applyFilter();
          } catch (err) {
            this.sendError({message:"Errore alla cancellazione della pausa abbonamento."});
            if(config.debug) console.error("Errore alla cancellazione della pausa abbonamento: " + err);
            if(err.statusCode === 401) {
              this.logout();
            }
          }
        }
      })
    },
    async applyFilter() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) order = 'DESC';
      }

      let filter = {
        query : {
          ...this.query,
          user_id : this.editingItem._id
        },
        limit : itemsPerPage,
        page, sort, order
      };
      try {
        const reply = await this.load({ collection : 'pauses', filter})
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      } catch (err) {
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento delle pause abbonamento."});
        if(config.debug) console.error("Errore al caricamento delle pause abbonamento: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      }

    },
    fiscalCodeUppercaser() {
      if(this.editingItem.fiscal_code) {
        this.editingItem.fiscal_code = this.editingItem.fiscal_code.toUpperCase();
      }
    },
    requiredErrorMessages(err) {
      if (err) return ["Campo richiesto"];
      return [];
    },
    cardExpirationClass( date ) {

      if(utils.toDateTime(date) < DateTime.now()) {
        return "error--text";
      }
      return "success--text";
    },
    formatDate : utils.formatDate,
    async loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      try {
        let { data } = await this.get({ collection: "users", id })

        if (data) {
          this.editingItem = data;

          if(this.editingItem.green_pass_expiration) {
            this.greenPassExp = utils.toDateTime(this.editingItem.green_pass_expiration).toISODate()
          }
          if(this.editingItem.medical_cert_expiration) {
            this.medicalCertExp = utils.toDateTime(this.editingItem.medical_cert_expiration).toISODate()
          }
        }

        let reply = await this.get({collection:'settings'})
        this.settings = reply.data

        this.resetPauseItem()
        await this.applyFilter()

        this.loading = false;


      } catch (err) {
        this.loading = false;
        let message = err.message
            ? err.message
            : "errore al caricamento dei dati utente";
        this.sendError({ message });
        this.$router.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      }

    },
    clearErrors() {
      this.noPasswordError = false;
      this.malformedPasswordError = false;
      this.confirmPasswordError = false;
      this.emailError = false;
      this.nameError = false;
      this.surnameError = false;
      this.roleError = false;
    },
    loadCard() {
      let sort = 'expiration';
      let order = 'DESC';

      let filter = {
        query : { cf : this.editingItem.fiscal_code },
        limit : 0, page : 1, sort, order
      };

      this.load({ collection : 'cards', filter}).then((reply)=>{
        if(reply.data && reply.data.length) {
          this.card = reply.data[0];
        } else {
          this.card = null
        }
      }).catch(console.log)
    },
    openPrivateLink( fileType ) {
      window.open(`${config.server}/api/users/${this.editingItem._id}/${fileType}?t=${this.token}`)
    },
    async save(afterAction) {
      this.clearErrors();
      let updating = this.updating;

      if (
          !this.editingItem.email ||
          !utils.isValidEmail(this.editingItem.email)
      ) {
        this.emailError = true;
        return;
      }
      if (!this.editingItem.name) {
        this.nameError = true;
        return;
      }
      if (!this.editingItem.surname) {
        this.surnameError = true;
        return;
      }
      if (!this.editingItem.role) {
        this.roleError = true;
        return;
      }

      if(!this.greenPassExp || !DateTime.fromISO(this.greenPassExp).isValid) {
        this.editingItem.green_pass_expiration = null
      } else {
        this.editingItem.green_pass_expiration = DateTime.fromISO(this.greenPassExp).toJSDate()
      }
      if(!this.medicalCertExp || !DateTime.fromISO(this.medicalCertExp).isValid) {
        this.editingItem.medical_cert_expiration = null
      } else {
        this.editingItem.medical_cert_expiration = DateTime.fromISO(this.medicalCertExp).toJSDate()
      }

      this.editingItem.training = utils.toInt(this.editingItem.training, 0)
      this.editingItem.weight = utils.toInt(this.editingItem.weight, 0)
      this.editingItem.height = utils.toInt(this.editingItem.height, 0)

      if (
          !updating ||
          (updating &&
              this.editingItem.password &&
              this.editingItem.password.length)
      ) {
        if (
            !this.editingItem.password ||
            this.editingItem.password.length === 0
        ) {
          this.noPasswordError = true;
          return;
        }
        if (this.editingItem.password.length < 8) {
          this.malformedPasswordError = true;
          return;
        }
        if (this.editingItem.password !== this.editingItem.confirm_password) {
          this.confirmPasswordError = true;
          return;
        }
      }

      let saveFn = updating ? this.update : this.insert;
      try {
        let reply = await saveFn({ collection: "users", data: this.editingItem })

        const id = reply.data._id;
        if(this.identityCardFront) {
          let formData = new FormData();
          formData.append("file", this.identityCardFront);
          let {data} = await this.upload({ where : `users/${id}/identityfront`, formData})
          reply.data = data;
        }
        if(this.identityCardRear) {
          let formData = new FormData();
          formData.append("file", this.identityCardRear);
          let {data} = await this.upload({ where : `users/${id}/identityrear`, formData})
          reply.data = data;
        }
        if(this.medicalCert) {
          let formData = new FormData();
          formData.append("file", this.medicalCert);
          let {data} = await this.upload({ where : `users/${id}/medical`, formData})
          reply.data = data;
        }
        if(this.scheda) {
          let formData = new FormData();
          formData.append("file", this.scheda);
          let {data} = await this.upload({ where : `users/${id}/scheda`, formData})
          reply.data = data;
        }



        this.sendSuccess({ message: "Utente salvato" });
        switch (afterAction) {
          case 1:
            //go back
            await this.$router.replace("/users");
            break;
          case 2:
            //stay
            if (this.$route.params.id === "new") {
              await this.$router.replace("/users/" + reply.data._id);
            } else {
              this.editingItem = reply.data;
            }
            break;
          case 3:
            // new
            if (this.$route.params.id === "new") {
              this.editingItem = {
                email: "",
                name: "",
                surname: "",
                role: "user",
                phone: "",
                fiscal_code: "",
                address: {
                  name: "",
                  city: "",
                  province: "",
                  zip_code: ""
                },
                user_type: "commercial",
                city_of_birth: '',
                province_of_birth: '',
                date_of_birth: new Date(),
                register_date: new Date(),
                password: "",
                confirm_password: "",
                sandbox: false,
                active: true,
                certificate: false,
                privacy_1 : true,
                privacy_2 : true,
                privacy_3 : true,
                home_kit : false,
                identity_card_front : '',
                identity_card_rear : '',
                medical_cert : '',
                medical_cert_expiration : null,
                scheda : '',
                green_pass_expiration : null,
                notes : '',
                user_notes : ''
              };
            } else {
              await this.$router.replace("/users/new");
            }
            break;
        }
      } catch (err) {
        if (config.debug) console.log("Save user error:", err);
        let message = err.message
            ? err.message
            : "errore al salvataggio dell'utente";
        this.sendError({ message });
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }




    }
  },
  mounted() {
    if (this.$route.params.id !== "new") {
      this.loadData();
    }
  }
};
</script>

<style scoped></style>
