<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="ma-4">
            <h2 class="display-1">Bike indoor</h2><v-spacer /><h2 class="display-1 text-uppercase">{{clockDate}} {{clockTime}}</h2>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row class="ma-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" lg="4" xl="3">
                      <v-menu v-model="menuOpen" :close-on-content-click="false" >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text rounded outlined color="accent" v-on="on" v-bind="attrs">{{formatDate(calendarDate)}}</v-btn>
                        </template>
                        <v-card tile>
                          <v-card-title>Imposta la data</v-card-title>
                          <v-card-text>
                            <v-text-field rounded filled label="Data" v-model="menuDate" type="date" class="flex-grow-0" style="min-width: 200px" ></v-text-field>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text color="accent" @click="applyMenuDate()">Applica</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>

                    </v-col>
                    <v-col cols="12" lg="8" xl="9" class="d-flex justify-center justify-lg-end">
                      <v-btn text rounded outlined color="accent" @click="setToday()">Oggi</v-btn>
                      <v-btn text rounded outlined color="accent" @click="cal.prev()" class="ml-1">Precedente</v-btn>
                      <v-btn text rounded outlined color="accent" @click="cal.next()" class="ml-1">Successivo</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-calendar
                      ref="calendar"
                      v-model="calendarDate"
                      type="day"
                      locale="it"
                      :weekdays="[1,2,3,4,5,6,0]"
                      first-time="5:30"
                      :interval-count="13"
                      :interval-minutes="90"
                      :short-intervals="false"
                      :events="events"
                      @click:event="showEventPopup"
                  >
                    <template #day-body="{ date, week }">
                      <div
                          class="v-current-time"
                          :class="{ first: date === week[0].date }"
                          :style="{ top: nowY }"
                      ></div>
                    </template>

                    <template #day-label-header="{ date }">
                      <div >
                        <h2>{{formatDate(date)}}</h2>
                        <v-chip class="ml-1 mr-1" dark x-small color="cyan" v-if="isToday(date)">OGGI</v-chip>
                        <v-chip class="ml-1 mr-1" dark x-small color="error" v-if="isHoliday(date)">CHIUSURA</v-chip>
                      </div>

                    </template>
                  </v-calendar>


                </v-col>

              </v-row>
              <v-divider v-if="is_editor" class="ml-4 mr-4" />
              <v-row v-if="is_editor" class="ma-2">
                <v-col cols="12">
                  <h3>Inserisci prenotazione</h3>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete outlined rounded label="Utente" :items="userOptions" v-model="newItem.user_id"
                                  :error="userError" :error-messages="requiredErrorMessage(userError)">

                    <template v-slot:selection="data">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.fiscal_code"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.fiscal_code"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select rounded outlined :items="validUserSeatsOptions" label="Pacchetto abbonamento" v-model="newItem.user_seat_id" style="max-width: 600px">
                    <template #item="{ item, on, attrs}">
                      <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                          <div >

                            <h5 v-if="getProductName(item.data.product_id)" class="no-select">Pacchetto "{{getProductName(item.data.product_id)}}" con accesso a sedute di tipo:</h5>
                            <h5 v-else class="no-select">Pacchetto con accesso a sedute di tipo:</h5>
                            <v-chip-group>
                              <v-chip x-small class="white--text font-weight-bold text-uppercase" v-for="(st, j) in item.data.seats_types" :key="`valid_listus_${item.data._id}_st_${j}`" :color="getSeatsTypeColor(st)">{{getSeatsTypeLabel(st)}}</v-chip>
                            </v-chip-group>
                            <p v-if="item.data.expiration" class="warning--text caption mb-0">Scadenza {{formatDate(item.data.expiration)}}.</p>
                            <p class="warning--text no-select caption mb-0" v-if="item.data.consumable && !item.data.expiration">Durata di {{item.data.duration}} giorni a partire dalla prima prenotazione.</p>
                            <p class="success--text no-select caption mb-0" v-if="item.data.consumable">{{item.data.available - item.data.used}} sedute disponibili.</p>

                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field outlined rounded label="Data" type="date" v-model="bookingDate" clearable @change="newItem.calendar_hour_id = null"
                                :error="dateError" :error-messages="requiredErrorMessage(dateError)"/>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select outlined rounded label="Ora" :items="bookingHoursOptions" v-model="newItem.calendar_hour_id" :disabled="!bookingDate"
                            :error="hourError" :error-messages="requiredErrorMessage(hourError)" @change="updateBookingDate()"/>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="currentPrefs.length">
                  <h4>Preferenze sulla lezione</h4>
                  <v-checkbox hide-details dense v-for="p in currentPrefs" :key="p._id" v-model="newItem.prefs_ids" :label="p.name" :value="p._id"></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3" class="d-flex justify-start mt-2">
                  <v-btn color="accent" rounded @click="insertBooking()">Inserisci</v-btn>
                </v-col>
              </v-row>
              <v-divider v-if="is_admin" class="mb-3" />

              <v-row v-if="is_admin">
                <v-col cols="12"><h3>Orari giornalieri</h3></v-col>
                <v-col cols="12">
                  <v-simple-table dark class="hour-table">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th :class="settings.open_days.lun ? 'text-center' : 'text-center error--text'">Lunedì</th>
                        <th :class="settings.open_days.mar ? 'text-center' : 'text-center error--text'">Martedì</th>
                        <th :class="settings.open_days.mer ? 'text-center' : 'text-center error--text'">Mercoledì</th>
                        <th :class="settings.open_days.gio ? 'text-center' : 'text-center error--text'">Giovedì</th>
                        <th :class="settings.open_days.ven ? 'text-center' : 'text-center error--text'">Venerdì</th>
                        <th :class="settings.open_days.sab ? 'text-center' : 'text-center error--text'">Sabato</th>
                        <th :class="settings.open_days.dom ? 'text-center' : 'text-center error--text'">Domenica</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(a, row) in maxHours" :key="'row'+row" >

                        <td v-if="lunHours[row]" :class="hourStyleClass(lunHours[row])">
                          <b>{{lunHours[row].time}}</b> > <b>{{endTimeOf(lunHours[row])}}</b>
                          <p v-if="lunHours[row].max_seats > 0"  class="error--text">{{lunHours[row].max_seats}} posti</p>
                          <p v-if="lunHours[row].restricted" class="error--text">posti non prenotabili</p>
                          <v-btn class="hour-del" text icon color="error" @click="removeHour(lunHours[row]._id)"><v-icon>mdi-close</v-icon></v-btn>
                          <v-btn class="hour-edit" text icon color="accent" @click="editHour(lunHours[row])"><v-icon>mdi-pencil</v-icon></v-btn>
                        </td><td v-else class="no-hour-cell"></td>

                        <td v-if="marHours[row]" :class="hourStyleClass(marHours[row])">
                          <b>{{marHours[row].time}}</b> > <b>{{endTimeOf(marHours[row])}}</b>
                          <p v-if="marHours[row].max_seats > 0"  class="error--text">{{marHours[row].max_seats}} posti</p>
                          <p v-if="marHours[row].restricted" class="warning--text">posti non prenotabili</p>
                          <v-btn class="hour-del" text icon color="error" @click="removeHour(marHours[row]._id)"><v-icon>mdi-close</v-icon></v-btn>
                          <v-btn class="hour-edit" text icon color="accent" @click="editHour(marHours[row])"><v-icon>mdi-pencil</v-icon></v-btn>
                        </td><td v-else class="no-hour-cell"></td>
                        <td v-if="merHours[row]" :class="hourStyleClass(merHours[row])">
                          <b>{{merHours[row].time}}</b> > <b>{{endTimeOf(merHours[row])}}</b>
                          <p v-if="merHours[row].max_seats > 0"  class="error--text">{{merHours[row].max_seats}} posti</p>
                          <p v-if="merHours[row].restricted" class="warning--text">posti non prenotabili</p>
                          <v-btn class="hour-del" text icon color="error" @click="removeHour(merHours[row]._id)"><v-icon>mdi-close</v-icon></v-btn>
                          <v-btn class="hour-edit" text icon color="accent" @click="editHour(merHours[row])"><v-icon>mdi-pencil</v-icon></v-btn>
                        </td><td v-else class="no-hour-cell"></td>
                        <td v-if="gioHours[row]" :class="hourStyleClass(gioHours[row])">
                          <b>{{gioHours[row].time}}</b> > <b>{{endTimeOf(gioHours[row])}}</b>
                          <p v-if="gioHours[row].max_seats > 0"  class="error--text">{{gioHours[row].max_seats}} posti</p>
                          <p v-if="gioHours[row].restricted" class="warning--text">posti non prenotabili</p>
                          <v-btn class="hour-del" text icon color="error" @click="removeHour(gioHours[row]._id)"><v-icon>mdi-close</v-icon></v-btn>
                          <v-btn class="hour-edit" text icon color="accent" @click="editHour(gioHours[row])"><v-icon>mdi-pencil</v-icon></v-btn>
                        </td><td v-else class="no-hour-cell"></td>
                        <td v-if="venHours[row]" :class="hourStyleClass(venHours[row])">
                          <b>{{venHours[row].time}}</b> > <b>{{endTimeOf(venHours[row])}}</b>
                          <p v-if="venHours[row].max_seats > 0"  class="error--text">{{venHours[row].max_seats}} posti</p>
                          <p v-if="venHours[row].restricted" class="warning--text">posti non prenotabili</p>
                          <v-btn class="hour-del" text icon color="error" @click="removeHour(venHours[row]._id)"><v-icon>mdi-close</v-icon></v-btn>
                          <v-btn class="hour-edit" text icon color="accent" @click="editHour(venHours[row])"><v-icon>mdi-pencil</v-icon></v-btn>
                        </td><td v-else class="no-hour-cell"></td>
                        <td v-if="sabHours[row]" :class="hourStyleClass(sabHours[row])">
                          <b>{{sabHours[row].time}}</b> > <b>{{endTimeOf(sabHours[row])}}</b>
                          <p v-if="sabHours[row].max_seats > 0"  class="error--text">{{sabHours[row].max_seats}} posti</p>
                          <p v-if="sabHours[row].restricted" class="warning--text">posti non prenotabili</p>
                          <v-btn class="hour-del" text icon color="error" @click="removeHour(sabHours[row]._id)"><v-icon>mdi-close</v-icon></v-btn>
                          <v-btn class="hour-edit" text icon color="accent" @click="editHour(sabHours[row])"><v-icon>mdi-pencil</v-icon></v-btn>
                        </td><td v-else class="no-hour-cell"></td>
                        <td v-if="domHours[row]" :class="hourStyleClass(domHours[row])">
                          <b>{{domHours[row].time}}</b> > <b>{{endTimeOf(domHours[row])}}</b>
                          <p v-if="domHours[row].max_seats > 0"  class="error--text">{{domHours[row].max_seats}} posti</p>
                          <p v-if="domHours[row].restricted" class="warning--text">posti non prenotabili</p>
                          <v-btn class="hour-del" text icon color="error" @click="removeHour(domHours[row]._id)"><v-icon>mdi-close</v-icon></v-btn>
                          <v-btn class="hour-edit" text icon color="accent" @click="editHour(domHours[row])"><v-icon>mdi-pencil</v-icon></v-btn>
                        </td><td v-else class="no-hour-cell"></td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12"><h4>Aggiungi ora al calendario</h4></v-col>
                <v-col cols="12" lg="3" xl="2">
                  <v-select outlined rounded label="Giorno della settimana" v-model="newHour.day" :items="days"/>
                </v-col>
                <v-col cols="12" lg="3" xl="2">
                  <v-text-field type="time" outlined rounded label="Ora" v-model="newHour.time" />
                </v-col>
                <v-col cols="12" lg="3" xl="2">
                  <v-text-field type="number" outlined rounded label="Durata in minuti" v-model="newHour.duration" hint="es. 60 = 1 ora, 90 = 1 ora e 1/2" />
                </v-col>
                <v-col cols="12" lg="3" xl="2">
                  <v-text-field type="number" outlined rounded label="N° posti per quest'ora" step="1" v-model="newHour.max_seats"
                  hint="0 = valore di default inserito in impostazioni" persistent-hint/>
                </v-col>
                <v-col cols="12" lg="3" xl="2">
                  <v-checkbox label="Impedisci prenotazioni per quest'ora" v-model="newHour.restricted" />
                </v-col>
                <v-col cols="12" lg="3" xl="2">
                  <v-btn color="accent" rounded @click="addHour()">Inserisci</v-btn>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editHourDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Modifica proprietà orario</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row v-if="editHourItem">
              <v-col cols="12" >
                <v-checkbox v-model="editHourItem.restricted" label="Impedisci prenotaazioni per quest'ora" />
              </v-col>
              <v-col cols="12">
                <v-text-field type="number" outlined rounded label="N° posti per quest'ora" step="1" v-model="editHourItem.max_seats"
                              hint="0 = valore di default inserito in impostazioni" persistent-hint/>
              </v-col>
              <v-col cols="12" >
                <h4>Impediscine la prenotazione per questi utenti:</h4>
                <v-row v-if="editHourItem.exclude_users && editHourItem.exclude_users.length">
                  <v-col cols="12" v-for="uid in editHourItem.exclude_users" class="d-flex align-center" :key="uid">
                    <v-icon>mdi-chevron-right</v-icon>
                    <span>{{getUserFullName(uid)}}</span>
                    <v-btn icon small color="accent" class="ml-2" @click="removeExcludeUser(uid)"><v-icon>mdi-close</v-icon></v-btn>
                  </v-col>
                </v-row>
                <span v-else class="font-italic">Non ci sono utenti esclusi dalla prenotazione di quest'ora</span>
                <v-row class="pt-3">
                  <v-col cols="12" class="pb-0"><h5>Aggiungi utente alla lista degli esclusi</h5></v-col>
                  <v-col cols="12" class="d-flex align-center">
                    <v-autocomplete rounded filled label="Scegli Utente" hide-details :items="excludeUserOptions" v-model="excludeUser"></v-autocomplete>
                    <v-btn rounded color="accent" @click="addExcludeUser()" class="ml-2">Aggiungi</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text  @click="editHourDialog = false">Annulla</v-btn>
          <v-btn color="error" text @click="updateEditHour()">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="eventDialog" max-width="400">
      <v-card>
        <v-card-title>Dettaglio prenotazione</v-card-title>
        <v-card-text v-if="selectedEvent">
          <h2>{{selectedEvent.name}}</h2>
          <p>{{formatDateExt(selectedEvent.start)}} dalle {{formatTime(selectedEvent.start)}} alle {{formatTime(selectedEvent.end)}}</p>
          <h3 v-if="selectedEvent.item.prefs && selectedEvent.item.prefs.length">Preferenze</h3>
          <ul v-if="selectedEvent.item.prefs && selectedEvent.item.prefs.length">
            <li v-for="pref in selectedEvent.item.prefs" :key="selectedEvent.item._id + '_'+ pref._id">{{pref.name}}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="is_editor" color="error" @click="removeSelectedBooking()" text>Rimuovi</v-btn>
          <v-spacer></v-spacer>
          <v-btn  @click="eventDialog = false;" text>Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import utils from '../utils';
import { mapGetters, mapActions } from 'vuex';
import {seatsTypes, seatsTypesOptions} from "../consts";
import {DateTime} from "luxon";

export default {
  name: "Gym",
  data () {
    return {
      clock : null,
      clockDate : '',
      clockTime : '',

      eventDialog : false,
      menuOpen : false,
      menuDate : DateTime.now().toISODate(),
      calendarDate : DateTime.now().toISODate(),
      calendarReady: false,
      bookings : [],
      holidays : {},
      selectedEvent : null,
      newItem : {
        type : 'gym',
        user_id : null,
        user_seat_id : null,
        calendar_hour_id : null,
        date : new Date(),
        prefs_ids : []
      },
      dateError : false,
      userError : false,
      hourError : false,

      editHourDialog : false,
      editHourItem : null,
      excludeUser: null,
      mailTo : '',
      settings : {
        home_kit_price : 0,
        home_kit_expedition_price : 0,
        home_kit_expedition_days : 0,
        purchase_expiration_days_after_first_use : 60,
        open_days : {
          lun: true,
          mar: true,
          mer: true,
          gio: true,
          ven: true,
          sab: true,
          dom: false,
        },
        free_seat_every_x_seats : 30,
        restricted_users_starts_from_num : 200,
        user_bookings_per_week : 2,
        user_bookings_per_day : 1,
        seats_days_of_validity : 365,
        bookings_per_hour : 9,
        one_to_one_per_hour : 1,
        cancel_within_minutes : 1,
        booking_within_minutes : 10,
        holidays : {
          natale : true,
          santo_stefano : true,
          capodanno : true,
          epifania : true,
          pasquetta : true,
          liberazione : true,
          lavoro : true,
          repubblica : true,
          ferragosto : true,
          santi : true,
          immacolata : true,
        }
      },
      newHoliday : {
        from : null,
        to: null
      },
      newHolidayDate : DateTime.now().toISODate(),
      newHolidayTime : DateTime.now().toFormat('HH') + ":00",

      hours : [],
      newHour : {
        name : '',
        type : 'gym',
        day : 1,
        time: "07:00",
        duration: 90,
        max_seats: 0,
        restricted : false,
      },
      days : [
        { text : 'Lunedì', value : 1},
        { text : 'Martedì', value : 2},
        { text : 'Mercoledì', value : 3},
        { text : 'Giovedì', value : 4},
        { text : 'Venerdì', value : 5},
        { text : 'Sabato', value : 6},
        { text : 'Domenica', value : 0},
      ]
    }

  },
  watch: {
    calendarDate() {
      this.menuDate = this.calendarDate;
      this.loadBookingData()
    }
  },
  computed : {
    ...mapGetters(['token', 'profile', 'is_root','users', 'is_editor', 'is_admin','bookingprefs','cards','userseats','products']),
    validUserSeats() {
      const today = DateTime.now().endOf('day')
      const filter = this.userseats.filter(s => {
        if(s.user_id !== this.newItem.user_id) return false
        if(!s.seats_types || !s.seats_types.includes(seatsTypes.gym)) return false
        if(s.expiration) {
          let exp = DateTime.fromISO(s.expiration)
          if(exp < today) {
            return false;
          }
        }
        return !(s.consumable && s.available <= s.used);

      })
      return filter
    },
    validUserSeatsOptions() {
      return this.validUserSeats.map(us => {
        let text = us.seats_types.map(this.getSeatsTypeLabel).join(', ')
        if(us.product_id) {
          const pr = utils.getObjectInArray(this.products,'_id', us.product_id)
          const prTitle = pr.kit ? pr.kit.title.it + " - " + pr.title.it : pr.title.it;
          text = prTitle + ` (${us.seats_types.map(this.getSeatsTypeLabel).join(', ')})`
        }
        return { text , data : us, value : us._id }
      })
    },
    currentPrefs() {
      return this.bookingprefs.filter(p => p.seats_type === seatsTypes.gym)
    },
    loading : {
      get() { return this.$store.state.loading; },
      set(val) { this.$store.state.loading = val; }
    },
    // booking
    bookingDate : {
      get() { return utils.toDateTime(this.newItem.date).toISODate(); },
      set(val) {
        if(!val) {
          this.newItem.date = null;
          return;
        }

        let h = '12:00'
        if(this.newItem.calendar_hour_id) {
          let calH = utils.getObjectInArray(this.hours, "_id", this.newItem.calendar_hour_id)
          h = calH.time
        }


        let parsed = DateTime.fromFormat(`${val} ${h}`, 'yyyy-MM-dd HH:mm', true);
        this.newItem.date = parsed.isValid ? parsed.toJSDate() : null;
      }
    },
    cal () {
      return this.calendarReady ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    userOptions() {
      return this.users.map((item)=>{
        return {text:item.surname + ' ' + item.name, fiscal_code : item.fiscal_code || "", value : item._id};
      })
    },
    bookingHoursOptions() {
      return this.hours.filter((item)=>{
        if(!this.newItem.date) return false
        console.log("this.newItem.date.getDay", this.newItem.date.getDay())
        return item.day === this.newItem.date.getDay();
      }).map((item) => {
        return { text: item.time, value : item._id }
      })
    },
    events() {
      return this.bookings.map(item => {

        let extraLabel = ""
        if(!item.user.medical_cert) {
          extraLabel = " (SENZA CERTIFICATO)"
        }
        if(item.user.medical_cert_expiration) {
          const dt = DateTime.fromISO(item.user.medical_cert_expiration)
          if(dt < DateTime.now().plus({hour : 96})) {
            extraLabel = " (CERTIFICATO IN SCADENZA)"
          }
          if(dt < DateTime.now()) {
            extraLabel = " (CERTIFICATO SCADUTO)"
          }

        }

        let cardLabel = ""
        let coni = this.cards.filter(c => {
          if(c.user_id !== item.user_id) return false
          const dt = DateTime.fromISO(c.expiration)
          return dt > DateTime.now()
        })
        if(!coni.length) {
          cardLabel = " (TESSERA MSP SCADUTA)"
        }

        let date = utils.toDateTime(item.date)
        return {
          _id : item._id,
          item : item,
          name : `${item.user.name} ${item.user.surname}${extraLabel}${cardLabel}`,
          start : date.toJSDate(),
          end : date.plus({hours:1}).toJSDate(),
          color : 'info',
          timed : true
        }
      })
    },

    // calendar
    lunHours() {
      return this.hours.filter((item) => item.day === 1);
    },
    marHours() {
      return this.hours.filter((item)=> item.day === 2);
    },
    merHours() {
      return this.hours.filter((item)=> item.day === 3);
    },
    gioHours() {
      return this.hours.filter((item)=> item.day === 4);
    },
    venHours() {
      return this.hours.filter((item)=> item.day === 5);
    },
    sabHours() {
      return this.hours.filter((item)=> item.day === 6);
    },
    domHours() {
      return this.hours.filter((item)=> item.day === 0);
    },
    maxHours() {
      return Math.max(this.lunHours.length, this.marHours.length, this.merHours.length, this.gioHours.length, this.venHours.length, this.sabHours.length, this.domHours.length)
    },
    excludeUserOptions() {
      return this.users.filter(item => !this.editHourItem || !this.editHourItem.exclude_users || !this.editHourItem.exclude_users.includes(item._id)).map(item => {
        return { text : item.name + " " + item.surname, value : item._id };
      })
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection','upload']),
    getSeatsTypeLabel( st ) {
      const opt = utils.getObjectInArray(seatsTypesOptions, 'value', st)
      return opt ? opt.text : st;
    },
    getSeatsTypeColor( st ) {
      const opt = utils.getObjectInArray(seatsTypesOptions, 'value', st)
      return opt ? opt.color : '';
    },
    getProductName(id) {
      const p = utils.getObjectInArray(this.products, '_id', id)
      if(!p) return null
      if(p.kit) {
        return p.kit.title.it + " - " + p.title.it
      }
      return p.title.it
    },
    applyMenuDate() {
      this.calendarDate = this.menuDate;
      this.menuOpen = false;
    },
    updateClock(){
      let now = DateTime.now();
      this.clockDate = now.toFormat('DDD')
      this.clockTime = now.toFormat('HH:mm:ss')
      if(this.cal) this.cal.updateTimes()
    },
    showEventPopup( e ) {
      //console.log(e)
      this.selectedEvent = e.event
      this.eventDialog = true;
    },
    updateBookingDate() {
      let temp = this.bookingDate
      this.bookingDate = temp;
    },
    requiredErrorMessage(err) {
      return err ? ['Campo richiesto'] : [];
    },
    formatDateExt(date) {
      return utils.toDateTime(date).toFormat('DDD')
    },
    formatTime(date) {
      return utils.toDateTime(date).toFormat('HH:mm')
    },
    isToday( date ) {
      return utils.toDateTime(date).startOf('day') === DateTime.now().startOf('day')
    },
    isHoliday( date ) {
      if(this.holidays[date] !== undefined) {
        return this.holidays[date];
      }
      this.checkIfHoliday( date )
      return false
    },
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    checkIfHoliday( date ) {
      this.get({collection:'holidays', id:'check/' + date }).then((reply)=>{
        this.holidays[date] = reply.data
      }).catch((err) => { console.log(err)})
    },
    async removeSelectedBooking() {
      this.loading = true;
      try {
        await this.delete({collection:'booking', id: this.selectedEvent._id})
        this.sendSuccess({message:"Prenotazione eliminata"})
      } catch (e) {
        let message = e && e.message ? e.message : "Oops! c'è stato un errore"
        this.sendError({message})
      }
      this.loading = false;
      this.eventDialog = false;
      this.loadBookingData()
    },
    setToday() {
      const now = this.cal.getNow()
      this.calendarDate = now.date;
    },
    loadBookingData() {

      this.loading = true;
      this.load({collection:'booking', filter : utils.fullFilter({ from : this.calendarDate, to : this.calendarDate }, 0,1, 'date', 'ASC')}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.bookings = reply.data.filter(item => item.calendar_hour.type === 'gym');
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento delle prenotazioni';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    insertBooking() {
      this.dateError = !this.newItem.date;
      this.userError = !this.newItem.user_id;
      this.hourError = !this.newItem.calendar_hour_id;
      if(this.dateError || this.userError || this.hourError) {
        return;
      }
      this.loading = true;
      this.insert({collection:'booking', data:this.newItem}).then((reply)=>{
        this.loading = false;
        this.loadBookingData();
      }).catch((err)=>{
        this.loading = false;
        let message = err.message ? err.message : "errore all'inserimento della prenotazione";
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },


    // calendar
    allowedMinutes: v => v === 0,
    hourStyleClass(hour){
      let classes = ["hour-cell"]
      if(hour.is_one_to_one) {
        classes.push('one-to-one')
      }
      if(hour.is_one_to_one) {
        classes.push('restricted')
      }
      return classes.join(' ')
    },
    endTimeOf(hour) {
      return DateTime.fromFormat(hour.time, 'HH:mm').plus({'minutes':hour.duration}).toFormat('HH:mm')
    },
    requiredErrorMessages(err) {
      if(err) return ["Campo richiesto"];
      return []
    },
    formatDate(date) {
      return utils.formatDate(date)
    },
    refreshHours() {
      this.loading = true;
      this.load({collection:'hours', filter : utils.fullFilter({ type : 'gym' }, 0,1, 'time', 'ASC')}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.hours = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento degli orari';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    isInvalidHour( hour ){

      const startT = DateTime.fromFormat(hour.time, 'HH:mm');
      if(!startT.isValid) {
        return 'Inserisci un orario valido (formato hh:mm)'
      }

      const duration = hour.duration ? hour.duration-1 : 59;
      const endT = startT.plus({'minutes':duration})



      for(let i = 0; i < this.hours.length; i++) {
        const  h = this.hours[i];
        if(h.day !== hour.day) continue;
        const hTstart =DateTime.fromFormat(h.time, 'HH:mm');
        const hTend = hTstart.plus({'minutes':duration})

        //console.log(rangeH.start.format('HH:mm'), rangeH.end.format('HH:mm'), rangeHT.start.format('HH:mm'), rangeHT.end.format('HH:mm'))
        if(utils.dateOverlaps(startT, endT, hTstart, hTend)) {
          return 'Inserisci un orario che non si sovrappone ad un altro'
        }
      }

      return false
    },
    addHour() {
      if(!this.newHour.time) {
        this.sendError({message:"Inserisci un'ora valida"});
        return;
      }

      let isInvalidHour = this.isInvalidHour(this.newHour)

      if(isInvalidHour) {
        this.sendError({message:isInvalidHour});
        return;
      }
      this.newHour.duration = utils.toInt(this.newHour.duration, 90);
      this.newHour.max_seats = utils.toInt(this.newHour.max_seats, 0);
      this.requestConfirm({title:"Richiesta conferma", message:"Vuoi davvero inserire questo orario?", callback: (confirmed) => {
          if(confirmed) {
            this.loading = true;
            this.insert({collection:'hours', data:this.newHour}).then((reply)=>{
              this.loading = false;
              if(reply.data) {
                this.refreshHours()
              }
            }).catch((err) => {
              this.loading = false;
              let message = err.message ? err.message : 'errore al salvataggio delle ore';
              this.sendError({message});
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });

    },
    getUserFullName( id ) {
      let filter = this.users.filter(item => item._id === id);
      if(!filter.length) return '-';
      return filter[0].name + " " + filter[0].surname;
    },
    addExcludeUser() {
      if(!this.editHourItem.exclude_users) this.editHourItem.exclude_users = [];
      this.editHourItem.exclude_users.push(this.excludeUser);
      this.excludeUser = null;
    },
    removeExcludeUser( id ) {
      if(!this.editHourItem.exclude_users) this.editHourItem.exclude_users = [];
      this.editHourItem.exclude_users = this.editHourItem.exclude_users.filter(item => item !== id);
    },
    editHour(item) {
      this.editHourItem = item;
      this.editHourDialog = true;
    },
    updateEditHour() {
      this.editHourDialog = false;
      this.loading = true;
      this.editHourItem.max_seats = utils.toInt(this.editHourItem.max_seats, 0)
      this.update({collection:'hours', data:this.editHourItem}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.refreshHours()
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al salvataggio';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    removeHour(id) {
      this.requestConfirm({title:"Richiesta conferma", message:"Vuoi davvero eliminare questa ora dal calendario settimanale?", callback: (confirmed) => {
          if(confirmed) {
            this.loading = true;
            this.delete({collection:'hours', id}).then((reply)=>{
              this.loading = false;
              this.refreshHours()
            }).catch((err) => {
              this.loading = false;
              let message = err.message ? err.message : 'errore al caricamento delle impostazioni';
              this.sendError({message});
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });
    },
    loadSettings() {
      this.loading = true;
      this.get({collection : 'settings'}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.settings = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento delle impostazioni del calendario';
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
  },
  mounted() {
    this.refreshCollection({collection:'cards'});
    this.refreshCollection({collection:'users'});
    this.refreshCollection({collection:'bookingprefs'});
    this.refreshCollection({collection:'userseats'});
    this.refreshCollection({collection:'products'});
    this.clock = setInterval(this.updateClock, 1000);
    this.calendarReady = true;
    this.scrollToTime()
    this.refreshHours()
    this.loadSettings()
    this.loadBookingData();
  },


  beforeDestroy() {
    clearInterval(this.clock);
  }
}
</script>
<style scoped>

.header-cal {
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}
.v-current-time .first::before {
  content: '';
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}

</style>